import * as React from "react";
const SvgLufe = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 300, height: 300, viewBox: "0 0 300 300", ...props }, /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: "100%", height: "100%", fill: "transparent" }), /* @__PURE__ */ React.createElement("text", { fontFamily: "Raleway", fontSize: 120, fontStyle: "normal", fontWeight: 900, style: {
  stroke: "none",
  strokeWidth: 1,
  strokeDasharray: "none",
  strokeLinecap: "butt",
  strokeDashoffset: 0,
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  fill: "#9ca3af",
  fillRule: "nonzero",
  opacity: 1,
  whiteSpace: "pre"
} }, /* @__PURE__ */ React.createElement("tspan", { x: 0, y: 200 }, "LUFE")));
export default SvgLufe;
