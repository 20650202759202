/* DON'T EDIT THIS FILE: edit original and run build again */ import { canAccessLineInbox } from "../../credit/line-inbox-permissions/permissions.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { SidebarMenuItem } from "../../framework/sidebar-type/sidebar-types.ts";
import { ChatIcon } from "../../framework/theme-icons/icon.ts";
import { chatListPath } from "./chat-path.ts";
import { chatTabId } from "./chat-tab-id.ts";
import { createUnreadCountPipe } from "./unread-count-pipe.front.ts";

const chatMenuItem: SidebarMenuItem = {
  id: chatTabId,
  getPath: () => chatListPath,
  label: "Chat",
  condition: ({ tenantHandler, roles }) =>
    userHasPermission(roles, canAccessLineInbox) &&
    !!tenantHandler.getLineOptions(),
  order: 3,
  hasPriority: true,
  getHasNotificationsPipe: ({ profile }) =>
    createUnreadCountPipe(profile.uid).pipe((unread) => ({
      unread,
      badge: unread
        ? unread + (unread > 1 ? " nuevos mensajes" : " nuevo mensaje")
        : null,
    })),
  icon: (className) => <ChatIcon className={className} />,
};

export default chatMenuItem;
