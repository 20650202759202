/* DON'T EDIT THIS FILE: edit original and run build again */ /* eslint-disable max-lines */
import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canEditLineOfficeBranch,
  canEditLinesWithoutAssigneeOrProducer,
  canEditOtherProducerLines,
  canEditOtherSalesLines,
  canEditOtherUserLines,
} from "./permissions.ts";

const linesEditPermissions: PermissionCollection = {
  namespace: "lines",
  permissions: [
    {
      name: canEditOtherUserLines,
      description: "Puede editar carpetas de otros usuarios",
      roles: [Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canEditOtherSalesLines,
      description: "Puede editar carpetas de comerciales",
      roles: [Roles.salesManager],
    },
    {
      name: canEditOtherProducerLines,
      description: "Puede editar carpetas de productores",
      roles: [Roles.headOfProducers],
    },

    {
      name: canEditLinesWithoutAssigneeOrProducer,
      description: "Puede editar carpetas sin comercial ni productor",
      roles: [Roles.salesManager],
    },
    {
      name: canEditLineOfficeBranch,
      description: "Puede editar la sucursal de una línea",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    },
  ],
};

export default linesEditPermissions;
