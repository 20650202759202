/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentHeaderParams } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import {
  emailChannelType,
  reutersChannelType,
  whatsappChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import { EMAIL } from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";

class MatchfinOnboardingOptions extends BaseOnboardingOptions {
  hasTerms(): boolean {
    return false;
  }

  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  hasProofOfFciRulebookSection(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [reutersChannelType, whatsappChannelType, emailChannelType];
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [
      {
        fullName: "JOAQUIN FERNANDEZ",
        address: "joaquinfernandez@example.com",
      },
      { fullName: "CARLOS SUAREZ", address: "carlossuarez@example.com" },
      { fullName: "ANDRES DURBAN", address: "andresdurban@example.com" },
      { fullName: "IGNACIO AIMI", address: "ignacioaimi@example.com" },
    ];
  }

  async getDocumentHeader(): Promise<ComponentType<DocumentHeaderParams>> {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const matchfinOnboardingOptions = new MatchfinOnboardingOptions();
