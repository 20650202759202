/* DON'T EDIT THIS FILE: edit original and run build again */ import { RolesMap } from "../../framework/permission/schema.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { TenantHandler } from "../../framework/tenant-handler-type/tenant-handler.ts";
import {
  canAccessUsers,
  canSeeOtherUsersRoles,
} from "../../framework/users-permissions/permissions.ts";
import { hasPermissionToListUsers } from "./permissions.ts";

export const canAccessUsersPage = ({
  roles,
  tenantHandler,
}: {
  roles: RolesMap;
  tenantHandler: TenantHandler;
}) =>
  userHasPermission(roles, canAccessUsers) &&
  userHasPermission(roles, canSeeOtherUsersRoles) &&
  hasPermissionToListUsers({ roles, tenantHandler });
