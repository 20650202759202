/* DON'T EDIT THIS FILE: edit original and run build again */ // users

export const canAccessUsers = "canAccessUsers";
export const canSeeOtherUsersRoles = "canSeeOtherUsersRoles";
export const canCreateInviteLinks = "canCreateInviteLinks";
export const canCreateProducerInviteLinks = "canCreateProducerInviteLinks";
export const canEditProfile = "canEditProfile";
export const canAddEndClient = "canAddEndClient";

export const canHaveSourceCode = "canHaveSourceCode";
export const canListAllUsers = "canListAllUsers";
export const canListDisabledUsers = "canListDisabledUsers";
export const canListInternalTenantProfiles = "canListInternalTenantProfiles";
export const canListExternalTenantProfiles = "canListExternalTenantProfiles";
export const canListProducerTenantProfiles = "canListProducerTenantProfiles";
export const canListTenantProfiles = "canListTenantProfiles";
export const canViewTenantProfiles = "canViewTenantProfiles";
export const canListProducerAssignedEndClientProfiles =
  "canListProducerAssignedEndClientProfiles";
export const canListAssignedEndClientProfiles =
  "canListAssignedEndClientProfiles";
export const canListUserCompanies = "canListUserCompanies";

// other users
export const canDisableOrEnableOtherUsers = "canDisableOrEnableOtherUsers";
export const canEditOtherUsersProfile = "canEditOtherUsersProfile";
export const canEditOtherUsersRoles = "canEditOtherUsersRoles";
export const canMigrateFinancerUsers = "canMigrateFinancerUsers";

// approval of users

export const canApproveRoleRequests = "canApproveRoleRequests";
export const canApproveEndClientRoleRequests =
  "canApproveEndClientRoleRequests";
export const canApproveProducerFolderEndClientRoleRequests =
  "canApproveProducerFolderEndClientRoleRequests";
export const canApproveAssignedFoldersEndClientRoleRequests =
  "canApproveAssignedFoldersEndClientRoleRequests";
export const canApproveEndClientAccessRequests =
  "canApproveEndClientAccessRequests";
export const canApproveProducerFolderEndClientAccessRequests =
  "canApproveProducerFolderEndClientAccessRequests";
export const canApproveAssignedFoldersEndClientAccessRequests =
  "canApproveAssignedFoldersEndClientAccessRequests";

// identity

export const canViewAdminsIdentity = "canViewAdminsIdentity";
export const canViewMatchfinAnalystsIdentity =
  "canViewMatchfinAnalystsIdentity";
