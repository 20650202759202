/* DON'T EDIT THIS FILE: edit original and run build again */ import { orderRoutingListingPath } from "../../orders/order-routing/types/routes.ts";
import { canViewOrderRouting } from "../../orders/order-permissions/permissions.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { SidebarMenuItem } from "../../framework/sidebar-type/sidebar-types.ts";
import { EditFileIcon } from "../../framework/theme-icons/icon.ts";

export const orderRoutingTabId = "orderRouting";

const orderRoutingMenuItem: SidebarMenuItem = {
  id: orderRoutingTabId,
  getPath: () => orderRoutingListingPath,
  label: "Órdenes",
  condition: ({ roles, tenantHandler }) =>
    userHasPermission(roles, canViewOrderRouting) &&
    tenantHandler.supportsOrderRouting(),
  order: 9,
  hasPriority: true,
  // Todo: Create icon for order routing section
  icon: (className) => <EditFileIcon className={className} />,
};

export default orderRoutingMenuItem;
