/* DON'T EDIT THIS FILE: edit original and run build again */ import { B2cOptions } from "../../../framework/tenant-handler-type/b2c-options.ts";
import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { BaseTenantHandler } from "../../../framework/tenant-handlers/base-tenant-handler.ts";
import { matchfinTenant } from "../../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";
import { ComponentType, SVGProps } from "react";
import { matchfinFullLabel } from "./full-label.ts";
import { matchfinB2cOptions } from "./matchfin-b2c-options.ts";
import { matchfinLineOptions } from "./matchfin-line-options.ts";
import { matchfinOnboardingOptions } from "./matchfin-onboarding-options.ts";

class MatchfinTenantHandler extends BaseTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_matchfin.svg?react")).default;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return matchfinOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return matchfinLineOptions;
  }

  getCode(): string {
    return matchfinTenant;
  }

  getPathLabel(): string | null {
    return null;
  }

  getLabel(): string {
    return "Matchfin";
  }

  getFullLabel(): string {
    return matchfinFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string {
    return "teamcompliance@matchfin.com.ar";
  }

  getCuit(): string | null {
    return "33-71759808-9";
  }

  getUserCompanyType() {
    return null;
  }

  supportsFinancerUsers(): boolean {
    return true;
  }

  doProducersSignUpViaMatchfinRealm(): boolean {
    return true;
  }

  doEndClientUsersHaveAccessToTheirEndClientLines(): boolean {
    return true;
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  usesDataLoadedStatus(): boolean {
    return true;
  }

  getTraderEmailAddress(): string | null {
    return "no-responder@matchfin.ar";
  }

  getBackOfficeEmailAddress(): string | null {
    return "no-responder@matchfin.ar";
  }

  getApprovedOrdersEmailAddress(): string | null {
    return "no-responder@matchfin.ar";
  }

  getRejectedOrdersEmailAddress(): string | null {
    return "no-responder@matchfin.ar";
  }

  supportsOrderRouting(): boolean {
    return true;
  }

  hasB2cFeature(): boolean {
    return true;
  }

  b2cOptions(): B2cOptions | null {
    return matchfinB2cOptions;
  }
}

const matchfinTenantHandler = new MatchfinTenantHandler();

export default matchfinTenantHandler;
