/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { listroTenant } from "./code.ts";
import { listroFullLabel } from "./full-label.ts";
import { listroLabel } from "./label.ts";
import { listroOnboardingOptions } from "./listro-onboarding-options.ts";

class ListroTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_listro.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return listroTenant;
  }

  getPathLabel(): string | null {
    return "listro";
  }

  getLabel(): string {
    return listroLabel;
  }

  getFullLabel(): string {
    return listroFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return listroOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "info@listrovalores.com";
  }

  getCuit(): string {
    return "30-70991814-8";
  }

  getCnvRegistryId(): string | null {
    return "155";
  }

  getColors(): ColorScheme {
    return {
      primary: "#adb0b3",
      secondary: "#606366",
      tertiary: "#FFFFFF",
    };
  }
}

const listroTenantHandler = new ListroTenantHandler();

export default listroTenantHandler;
