/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { mediterraneaTenant } from "./code.ts";
import { mediterraneaFullLabel } from "./full-label.ts";
import { mediterraneaLabel } from "./label.ts";
import { mediterraneaOnboardingOptions } from "./mediterranea-onboarding-options.ts";

class MediterraneaTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_mediterranea.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return mediterraneaTenant;
  }

  getPathLabel(): string | null {
    return "mediterranea";
  }

  getLabel(): string {
    return mediterraneaLabel;
  }

  getFullLabel(): string {
    return mediterraneaFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return mediterraneaOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@mediterranea.com";
  }

  getComplianceEmailAddress(): string | null {
    return "info@mediterraneavyasa.com";
  }

  getCuit(): string {
    return "33-70932762-9";
  }

  getCnvRegistryId(): string | null {
    return "128";
  }

  getColors(): ColorScheme {
    return {
      primary: "#006C5B",
      secondary: "#006C5B",
      tertiary: "#001612",
    };
  }
}

const mediterraneaTenantHandler = new MediterraneaTenantHandler();

export default mediterraneaTenantHandler;
