/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentHeaderParams,
  ProofOfFciRulebookParams,
  RulebookValueOption,
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import { ComponentImporter } from "../../../framework/theme/component-importer-type.ts";
import {
  emailChannelType,
  webHomeChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import {
  AFIP,
  EMAIL,
} from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";
import { argenfundsTermsOfUseTitle } from "./onboarding-terms-of-use-title.ts";
import rulebookValueOptions from "./rulebook-value-options.ts";

class ArgenfundsOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return argenfundsTermsOfUseTitle;
  }

  termsOfUseImporter: ComponentImporter<TermsOfUseParams> = () => {
    return import("./onboarding-terms-of-use.tsx");
  };

  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  hasSwornStatementAboutLegality(): boolean {
    return true;
  }

  hasProofOfFciRulebookSection(): boolean {
    return true;
  }

  hasProofOfFciRulebookContent(): boolean {
    return true;
  }

  proofOfFciRulebookContentImporter: ComponentImporter<ProofOfFciRulebookParams> =
    () => {
      return import("./proof-of-fci-rulebook.tsx");
    };

  proofOfFciRulebookAfterLabelImporter: ComponentImporter<ProofOfFciRulebookParams> =
    () => {
      return import("./proof-of-fci-rulebook-after-label.tsx");
    };

  getRulebookValueOptions(): Record<string, RulebookValueOption> {
    return rulebookValueOptions;
  }

  getSupportedChannelTypes(): string[] {
    return [webHomeChannelType, emailChannelType];
  }

  supportsWebHomeChannelAccessType(): boolean {
    return true;
  }

  webHomeHeaderImporter: ComponentImporter<WebHomeHeaderParams> = () => {
    return import("./web-home-header.tsx");
  };

  hasFatca(): "simple" | "full" | "none" {
    return "none";
  }

  hasMatbaRofex(): boolean {
    return false;
  }

  hasSgrComissions(): boolean {
    return false;
  }

  hasIsQualifiedInvestorText(): boolean {
    return true;
  }

  async getDocumentHeader(): Promise<ComponentType<DocumentHeaderParams>> {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const argenfundsOnboardingOptions = new ArgenfundsOnboardingOptions();
