/* DON'T EDIT THIS FILE: edit original and run build again */ import { RolesMap } from "../../../framework/permission/schema.ts";
import { userHasPermission } from "../../../framework/permission/user-has-permission.ts";
import {
  canApproveAssignedFoldersEndClientAccessRequests,
  canApproveEndClientAccessRequests,
  canApproveProducerFolderEndClientAccessRequests,
} from "../../../framework/users-permissions/permissions.ts";

export const userHasPermissionToListApproveEndClientAccessRequests = (
  roles: RolesMap
): boolean =>
  userHasPermission(roles, canApproveEndClientAccessRequests) ||
  userHasPermission(roles, canApproveProducerFolderEndClientAccessRequests) ||
  userHasPermission(roles, canApproveAssignedFoldersEndClientAccessRequests);
