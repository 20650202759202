/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  AssignedEntitiesList,
  AssignedEntitiesListPartial,
  AssignedEntitiesListTypes,
} from "../../../credit/assigned-entities/data-pipe.ts";
import { getProfileCol } from "../../../framework/auth-profile/profile.ts";
import { Profile, UserProfile } from "../../../framework/auth-profile/schema.ts";
import { getRoleRequestsCol } from "../../../framework/auth-role-requests/role-request.ts";
import {
  RoleRequestStatus,
  UserRoleRequest,
} from "../../../framework/auth-role-requests/schema.ts";
import { mapArrayToObject } from "../../../framework/core/map-array-to-object.ts";
import { ascSorter } from "../../../framework/core/sort-by-many.ts";
import { WithId } from "../../../framework/core/with-id.ts";
import { combinePipesAsObject } from "../../../framework/data-pipe/combine-pipes-as-object.ts";
import { DataPipe } from "../../../framework/data-pipe/data-pipe.ts";
import { joinPipe } from "../../../framework/data-pipe/join-pipe.ts";
import { leftJoin } from "../../../framework/data-pipe/left-join.ts";
import { batchColPipe } from "../../../framework/firebase/batch-pipe.ts";
import { colPipe } from "../../../framework/firebase/firestore-pipe.ts";
import { firestoreDocumentId } from "../../../framework/firebase/firestore-wrappers-types.ts";
import { FirestoreCollection } from "../../../framework/firebase/firestore-wrappers.ts";
import { rolesSortingIndex } from "../../../framework/internal-roles/role-extras.ts";
import { getUserRolesCol } from "../../../framework/permission/roles.ts";
import {
  Roles,
  RolesMap,
  UserRoles,
} from "../../../framework/permission/schema.ts";
import { userHasPermission } from "../../../framework/permission/user-has-permission.ts";
import {
  canListProducerAssignedEndClientProfiles,
  canListTenantProfiles,
} from "../../../framework/users-permissions/permissions.ts";
import { userHasPermissionToListApproveEndClientsUsers } from "../../../framework/users/approve-end-client-users/permissions.ts";
import { userHasPermissionToListApproveUsers } from "../../../framework/users/approve-tenant-users/permissions.ts";
import { UsersListPipeFilters } from "../../../framework/users/filter-types.ts";
import {
  FullUserInfo,
  UsersListDataPipeArgs,
} from "../../../framework/users/list-data-pipe-params.ts";

const createFilteredByAssignedUsersPipe = (
  col: FirestoreCollection<Profile>,
  entityIdsList: AssignedEntitiesListPartial
): DataPipe<UserProfile[]> =>
  batchColPipe(
    col,
    "endClientIds",
    "array-contains-any",
    entityIdsList.entityIds,
    "uid",
    "listAssignedTenantUsers"
  );

const createProfilesPipe = (
  roles: RolesMap,
  tenant: string,
  entityIdsList: AssignedEntitiesList,
  profile?: UserProfile
): DataPipe<UserProfile[]> => {
  const col = getProfileCol()
    .where("tenant", "==", tenant)
    .where("disabled", "==", false);
  if (
    userHasPermission(roles, canListTenantProfiles) ||
    userHasPermission(roles, canListProducerAssignedEndClientProfiles)
    // we eventually should filter producerAssigned before fetching
  ) {
    return colPipe(col, "uid", "profileTenantPipe");
  } else {
    // canListAssignedEndClientProfiles
    const pipe = createFilteredByAssignedUsersPipe(
      col,
      entityIdsList as AssignedEntitiesListPartial
    );
    if (profile) {
      return pipe.pipe((profiles) => {
        // add profile (self) to results
        return [profile, ...profiles];
      });
    }
    return pipe;
  }
};

const createUserRolesPipe = (
  profiles: UserProfile[]
): DataPipe<WithId<UserRoles, "uid">[]> =>
  batchColPipe(
    getUserRolesCol(),
    firestoreDocumentId(),
    "in",
    profiles.map(({ uid }) => uid),
    "uid",
    "userRolesTenantUsersPipe"
  );

export const createProfilesAndRolesPipe = (
  roles: RolesMap,
  tenant: string,
  possibleRoles: RolesMap,
  entityIdsList: AssignedEntitiesList,
  profile?: UserProfile
): DataPipe<(UserProfile & { roles: Roles[] | null })[]> =>
  createProfilesPipe(roles, tenant, entityIdsList, profile).pipeToPipe(
    (profiles) =>
      createUserRolesPipe(profiles).pipe((roles) =>
        leftJoin(profiles, roles, "uid", "uid", (a, b) => ({
          ...a,
          roles: (b ? b.roles : [])
            .filter((role) => possibleRoles[role])
            .sort(ascSorter((role: Roles) => rolesSortingIndex[role])),
        }))
      )
  );

const createRoleRequestsPipe = (
  roles: RolesMap,
  tenant: string,
  entityIdsList: AssignedEntitiesList
): DataPipe<UserRoleRequest[]> => {
  if (
    !(
      userHasPermissionToListApproveUsers(roles) ||
      userHasPermissionToListApproveEndClientsUsers(roles)
    ) ||
    entityIdsList.type === AssignedEntitiesListTypes.none
  ) {
    return DataPipe.withInitialData(null, []);
  }
  let col = getRoleRequestsCol()
    .where("tenant", "==", tenant)
    .where("status", "==", RoleRequestStatus.authorized);
  if (!userHasPermissionToListApproveUsers(roles)) {
    col = col.where("isEndClient", "==", true);
  }
  let pipe: DataPipe<UserRoleRequest[]>;
  if (entityIdsList.type === AssignedEntitiesListTypes.partial) {
    pipe = batchColPipe(
      col,
      "entityId",
      "in",
      entityIdsList.entityIds,
      "uid",
      "partialRoleRequestsTenantUsersPipe"
    );
  } else {
    // AssignedEntitiesListTypes.all
    pipe = colPipe(col, "uid", "allRoleRequestsTenantUsersPipe");
  }
  return pipe;
};

type UserProfileWithRolesAndRoleRequest = UserProfile & {
  roles?: Roles[];
  roleRequest?: UserRoleRequest;
};

const createUsersPipe = (
  profile: UserProfile,
  tenant: string,
  roles: RolesMap,
  possibleRoles: RolesMap,
  entityIdsList: AssignedEntitiesList
): DataPipe<UserProfileWithRolesAndRoleRequest[]> =>
  combinePipesAsObject({
    requests: createRoleRequestsPipe(roles, tenant, entityIdsList),
    profiles: createProfilesAndRolesPipe(
      roles,
      tenant,
      possibleRoles,
      entityIdsList,
      profile
    ),
  }).pipe(({ profiles, requests }) =>
    leftJoin(profiles, requests, "uid", "uid", (a, b) => ({
      ...a,
      roles: a.roles ?? undefined,
      roleRequest: b ?? undefined,
    }))
  );

const decorate = (pipe: DataPipe<UserProfileWithRolesAndRoleRequest[]>) =>
  joinPipe(
    pipe,
    ({ assigneeUids }) => {
      if (assigneeUids) {
        return batchColPipe(
          getProfileCol(),
          firestoreDocumentId(),
          "in",
          assigneeUids,
          "uid",
          "decorateWithAssigneeProfilesPipe"
        );
      } else {
        return DataPipe.withInitialData(null, []);
      }
    },
    (base: UserProfileWithRolesAndRoleRequest, assignedProfiles) => ({
      ...base,
      assignedProfiles,
    })
  );

const filterResults = (
  pipe: DataPipe<FullUserInfo[]>,
  filters: UsersListPipeFilters,
  entityIdsList: AssignedEntitiesList
): DataPipe<FullUserInfo[]> =>
  pipe.pipe((users) => {
    let entityIdsMap: Record<string, boolean> | null;
    if (entityIdsList.type === AssignedEntitiesListTypes.partial) {
      entityIdsMap = mapArrayToObject(entityIdsList.entityIds, (id) => [
        id,
        true,
      ]);
    }
    return users.filter(({ roles, endClientIds }) => {
      if (!roles) {
        throw new Error("rolesAreMissing");
      }
      return (
        !roles.includes(Roles.pendingApproval) &&
        !roles.includes(Roles.tenantRealmPendingApproval) &&
        roles.some((role) => filters.visibleRoles[role]) &&
        (!(roles.includes(Roles.endClientUser) && entityIdsMap) ||
          endClientIds?.some((id) => entityIdsMap?.[id]))
      );
    });
  });

export const createListTenantUsersPipe = ({
  profile,
  roles,
  tenant,
  filters,
  possibleRoles,
  entityIdsList,
}: UsersListDataPipeArgs): DataPipe<FullUserInfo[]> => {
  if (
    filters?.showEnabled === false ||
    !Object.values(filters.visibleRoles).some((value) => value)
  ) {
    return DataPipe.withInitialData(null, []);
  }
  if (!profile || !roles || !tenant || !possibleRoles || !entityIdsList) {
    throw new Error("missingArgs");
  }
  const pipe = createUsersPipe(
    profile,
    tenant,
    roles,
    possibleRoles,
    entityIdsList
  );
  return filterResults(decorate(pipe), filters, entityIdsList);
};
