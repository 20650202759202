/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessOnboardingAdminSections,
  canAccessOnboardingLog,
  canAccessOnboardings,
  canAddCommentForAnySource,
  canAddCommentOnboarding,
  canAddOnboardings,
  canBackupOnboardings,
  canChangeToAllStatuses,
  canCreateVideoSelfieRequests,
  canDeleteOnboardingComments,
  canDeleteVideoSelfies,
  canEditOnboardingsWithoutAssigneeOrProducer,
  canEditOtherProducerOnboardings,
  canEditOtherSalesOnboardings,
  canEditOtherUsersOnboardings,
  canEditSubmittedOnboardings,
  canListOnboardings,
  canViewOnboardingsWithCommonStatuses,
  canViewOnboardingsWithInfoRequestStatus,
  canViewOnboardingsWithInitialLoadStatus,
  canViewVideoSelfies,
} from "./permissions.ts";

const onboardingPermissions: PermissionCollection = {
  namespace: "onboarding",
  permissions: [
    {
      name: canAccessOnboardingAdminSections,
      description:
        "Puede acceder a las secciones administradoras de los Onboardings: Estado, Datos básicos, Renaper",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
      ],
    },
    {
      name: canAccessOnboardingLog,
      description: "Puede acceder al registro de onboardings",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
      ],
    },
    {
      name: canAccessOnboardings,
      description:
        "Tiene acceso a los Onboardings y a las notificaciones por nuevos dictámenes",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },
    {
      name: canAddCommentForAnySource,
      description: "Puede agregar comentarios para cualquier fuente",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
    {
      name: canAddCommentOnboarding,
      description:
        "Puede agregar un comentario en la sección Dictámenes de los Onboardings",
      roles: [Roles.tenantAdmin, Roles.admin, Roles.complianceOfficer],
    },
    {
      name: canAddOnboardings,
      description: "Puede agregar nuevos Onboardings",
      roles: [
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.endClientUser,
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
    {
      name: canChangeToAllStatuses,
      description:
        "Puede accedes a la sección Estado y modificar el estado a cualquier otro",
      roles: [Roles.admin, Roles.tenantAdmin],
    },
    {
      name: canCreateVideoSelfieRequests,
      description: "Puede crear solicitudes de video-selfie",
      roles: [
        Roles.endClientUser,
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.complianceOfficer,
        Roles.tenantAdmin,
        Roles.admin,
      ],
    },
    {
      name: canDeleteVideoSelfies,
      description: "Puede eliminar video-selfies",
      roles: [Roles.complianceOfficer, Roles.tenantAdmin, Roles.admin],
    },

    {
      name: canEditOtherProducerOnboardings,
      description:
        " Puede editar los Onboardings cargados por otros productores, que esten dentro del mismo tenant",
      roles: [Roles.headOfProducers],
    },
    {
      name: canEditOtherSalesOnboardings,
      description:
        "Puede editar los onboardings de otros comerciales, que esten dentro del mismo tenant",
      roles: [Roles.salesManager],
    },

    {
      name: canEditOnboardingsWithoutAssigneeOrProducer,
      description:
        "Puede editar Onboardings que no tengan comercial ni productor asociado",
      roles: [Roles.salesManager],
    },
    {
      name: canEditOtherUsersOnboardings,
      description:
        "Puede editar los Onboardings de otros usuarios, idependientemente de su rol",
      roles: [Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canEditSubmittedOnboardings,
      description: "Puede editar Onboardings ya enviados",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.analyst,
        Roles.matchfinAnalyst,
      ],
    },
    {
      name: canListOnboardings,
      description: "Puede ver en formato listado los Onboardings cargados",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
      ],
    },
    {
      name: canViewOnboardingsWithCommonStatuses,
      description:
        "Puede ver los Onboardings en estados comunes: todos excepto Carga en proceso o Información solicitada",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
      ],
    },

    {
      name: canViewOnboardingsWithInfoRequestStatus,
      description: "Puede ver los Onboardings en estado Información solicitada",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },

    {
      name: canViewOnboardingsWithInitialLoadStatus,
      description: "Puede ver los Onboardings en estado Carga en proceso",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },
    {
      name: canViewVideoSelfies,
      description: "Puede ver los video-selfies de los Onboardings",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.complianceOfficer,
      ],
    },
    {
      name: canDeleteOnboardingComments,
      description: "El usuario puede eliminar comentarios en Onboardings",
      roles: [Roles.admin, Roles.tenantAdmin, Roles.complianceOfficer],
    },
    {
      name: canBackupOnboardings,
      description: "Puede hacer un backup de los onboardings de la cuenta",
      roles: [Roles.admin, Roles.tenantAdmin, Roles.complianceOfficer],
    },
  ],
};

export default onboardingPermissions;
