/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  canAccessCounterpartiesPage,
  canViewCounterpartiesLog,
} from "../../framework/counterparties-permissions/permissions.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { SidebarMenuItem } from "../../framework/sidebar-type/sidebar-types.ts";
import { BuildingIcon } from "../../framework/theme-icons/icon.ts";
import { companyListingWebPath } from "./company-listing-web-path.ts";
import { counterpartiesLogWebPath } from "./counterparties-log-web-path.ts";
import { getCounterpartiesHasNotificationPipe } from "./counterparties-menu-option.ts";

export const counterpartiesTabId = "counterparties";

const counterpartiesMenuItem: SidebarMenuItem = {
  id: counterpartiesTabId,
  getPath: ({ roles }) =>
    userHasPermission(roles, canAccessCounterpartiesPage)
      ? companyListingWebPath
      : counterpartiesLogWebPath,
  label: "Contrapartes",
  condition: ({ tenantHandler, roles }) =>
    (userHasPermission(roles, canAccessCounterpartiesPage) ||
      userHasPermission(roles, canViewCounterpartiesLog)) &&
    !!tenantHandler.getLineOptions(),
  getHasNotificationsPipe: getCounterpartiesHasNotificationPipe,
  order: 7,
  hasPriority: false,
  icon: (className) => <BuildingIcon className={className} />,
};

export default counterpartiesMenuItem;
