/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canDeleteRiskMatrixComments,
  canViewRiskMatrix,
} from "./permissions.ts";

const riskMatrixPermissions: PermissionCollection = {
  namespace: "riskMatrix",
  permissions: [
    {
      name: canDeleteRiskMatrixComments,
      description:
        "El usuario puede eliminar comentarios en las matrices de riesgo",
      roles: [Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canViewRiskMatrix,
      description: "Puede acceder a la matriz de riesgos",
      roles: [Roles.admin, Roles.complianceOfficer],
    },
  ],
};

export default riskMatrixPermissions;
