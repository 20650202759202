/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canListOtherUsersAssignedEntities,
  canListProducerAssignedEntities,
  canViewOwnAssignedEntities,
} from "./permissions.ts";

const assignedEntitiesPermissions: PermissionCollection = {
  namespace: "assignedEntities",
  permissions: [
    {
      name: canViewOwnAssignedEntities,
      description: "Puede ver sus propias entidades asignadas",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
      ],
    },
    {
      name: canListOtherUsersAssignedEntities,
      description: "Puede listar las entidadaes asignadas a otros usuarios",
      roles: [Roles.admin, Roles.tenantAdmin, Roles.salesManager],
    },
    {
      name: canListProducerAssignedEntities,
      description: "Puede listar entidades asignadas a productores",
      roles: [Roles.headOfProducers],
    },
  ],
};

export default assignedEntitiesPermissions;
