/* DON'T EDIT THIS FILE: edit original and run build again */ import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { SidebarMenuItem } from "../../framework/sidebar-type/sidebar-types.ts";
import { canEditTenantSettings } from "../../framework/tenant-permissions/permissions.ts";
import { GearIcon } from "../../framework/theme-icons/icon.ts";
import { tenantSettingsPath } from "./settings-url.ts";

export const tenantSettingsTabId = "tenantSettings";

const tenantSettingsMenuItem: SidebarMenuItem = {
  id: tenantSettingsTabId,
  getPath: () => tenantSettingsPath,
  label: "Ajustes",
  condition: ({ roles, tenantHandler }) =>
    userHasPermission(roles, canEditTenantSettings) &&
    tenantHandler.hasSemaphoreSettings(),
  // hide if there are no settings sections to use
  order: 8,
  hasPriority: false,
  icon: (className) => <GearIcon className={className} />,
};

export default tenantSettingsMenuItem;
