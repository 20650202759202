/* DON'T EDIT THIS FILE: edit original and run build again */ export const canListTenantPrincipalAccounts = "canListPrincipalAccounts";
export const canListPartyPrincipalAccounts = "canListPartyPrincipalAccounts";
export const canListEndClientPrincipalAccounts =
  "canListEndClientPrincipalAccounts";

export const canCreateOrderRouting = "canCreateOrderRouting";
export const canCreatePreorders = "canCreatePreorders";
export const canCreateOrders = "canCreateOrders";

export const canEditOrderRouting = "canEditOrderRouting";
export const canEditAnyOrderRouting = "canEditAnyOrderRouting";
export const canEditAnyUnderReviewOrderRouting =
  "canEditUnderReviewOrderRouting";
export const canEditCreatedOrderRouting = "canEditCreatedOrderRouting";
export const canEditAnySentOrderRouting = "canEditAnySentOrderRouting";

export const canRecreateAnyOrderRouting = "canRecreateAnyOrderRouting";
export const canRecreateCreatedOrderRouting = "canRecreateCreatedOrderRouting";

export const canTraderTakeOrderRouting = "canTraderTakeOrderRouting";

export const canViewOrderRouting = "canViewOrderRouting";
export const hasOrderRoutingTenantViewAccess =
  "hasOrderRoutingTenantViewAccess";
export const hasOrderRoutingTraderViewAccess =
  "hasOrderRoutingTraderViewAccess";
export const hasOrderRoutingPartyViewAccess = "hasOrderRoutingPartyViewAccess";
export const hasOrderRoutingEndClientViewAccess =
  "hasOrderRoutingEndClientViewAccess";

export const canSetAnyOrderRoutingStatus = "canSetAnyOrderRoutingStatus";
export const canSetUnderReviewOrderRoutingStatus =
  "canSetUnderReviewOrderRoutingStatus";
export const canSetAnnulledCreatedOrderRoutingStatus =
  "canSetAnnulledOrderRoutingStatus";
export const canSetActiveOrderRoutingStatus = "canSetActiveOrderRoutingStatus";
export const canSetDiscardedOrderRoutingStatus =
  "canSetDiscardedOrderRoutingStatus";
export const canResolveAnyModifiedOrderRoutingStatus =
  "canResolveAnyModifiedOrderRoutingStatus";
export const canResolveCreatedModifiedOrderRoutingStatus =
  "canResolveCreatedModifiedOrderRoutingStatus";
export const canResolvePendingOrderRoutingStatus =
  "canResolvePendingOrderRoutingStatus";
export const canResolveUnderReviewOrderRoutingStatus =
  "canResolveUnderReviewOrderRoutingStatus";
export const canResolveSentOrderRoutingStatus =
  "canResolveSentOrderRoutingStatus";
export const canSetOperatedOrderRoutingStatus =
  "canSetOperatedOrderRoutingStatus";
export const canResolveAnyActiveOrderRoutingStatus =
  "canResolveAnyActiveOrderRoutingStatus";
export const canResolveNonTakenActiveOrderRoutingStatus =
  "canResolveNonTakenActiveOrderRoutingStatus";
export const canResolveAnyUnfinishedOrderRoutingStatus =
  "canResolveAnyUnfinishedOrderRoutingStatus";
export const canResolveCreatedUnfinishedOrderRoutingStatus =
  "canResolveCreatedUnfinishedOrderRoutingStatus";
export const canReadPrimaryData = "canReadPrimaryData";
