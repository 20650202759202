/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canViewFinancerStatuses,
  canViewFullInfoLineAdminSections,
  canViewInternalLineCommonSections,
  canViewInternalLineStatusSection,
  canViewLineChooseYourAlycSection,
  canViewLineFinancingNeeds,
  canViewLineHasChosenAlyc,
  canViewLineInstruments,
  canViewLinePreApprovals,
  canViewNosisSection,
  canViewPresentationLineAdminSections,
} from "./permissions.ts";

const linesViewPermissions: PermissionCollection = {
  namespace: "lines",
  permissions: [
    {
      name: canViewFullInfoLineAdminSections,
      description:
        "Puede editar los datos básicos, el análisis crediticio, la información de Nosis, los balances y ventas del reporte",
      roles: [
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    }, // este permiso aunque efectivo, creo que el nombre es inadecuado, ya que habilta y deshabilita secciones de la vista de edicion, no la de presentacion.
    {
      name: canViewPresentationLineAdminSections,
      description:
        "Puede ver la información de la sección Admin de la carpeta en modo presentación",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.b2cEndclientUser,
        Roles.matchfinEndClientUser,
      ],
    },
    {
      name: canViewInternalLineStatusSection,
      description: "Puede ver la seccion Estado de la carpeta",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },
    {
      name: canViewInternalLineCommonSections,
      description:
        "Puede ver las secciones internas comunes: descripción de la actividad, fiadores, principales clientes, proveedores, administración coporativa",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.endClientUser,
        Roles.b2cEndclientUser,
        Roles.matchfinEndClientUser,
      ],
    },
    {
      name: canViewLineInstruments,
      description: "Puede ver los intrumentos de la carpeta",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.salesManager,
        Roles.headOfProducers,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.matchfinEndClientUser,
        Roles.b2cEndclientUser,
        Roles.portfolioManager,
      ],
    },
    {
      name: canViewLineFinancingNeeds,
      description: "Puede ver las necesidades financieras de la carpeta",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.salesManager,
        Roles.headOfProducers,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.portfolioManager,
      ],
    },
    {
      name: canViewNosisSection,
      description: "Puede ver la seccion de Nosis",
      roles: [Roles.admin, Roles.tenantAdmin],
    },
    {
      name: canViewLineHasChosenAlyc,
      description:
        "Puede ver la columna de Busca financiamiento y la sección Pre-aprobados",
      roles: [Roles.admin, Roles.matchfinAnalyst],
    },

    {
      name: canViewLineChooseYourAlycSection,
      description: "Puede ver la sección elige tu Alyc",
      roles: [Roles.matchfinEndClientUser, Roles.b2cEndclientUser],
    },
    {
      name: canViewFinancerStatuses,
      description: "Puede ver el estado de los inversionistas",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },
    {
      name: canViewLinePreApprovals,
      description: "Puede ver la preaprobación de carpeta en el listado",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },
  ],
};

export default linesViewPermissions;
