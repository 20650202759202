/* DON'T EDIT THIS FILE: edit original and run build again */ // FIXME: replace uses with Roles enum
export const adminRole = "admin";
export const tenantAdminRole = "tenantAdmin";
export const endClientUserRole = "endClientUser";
export const matchfinEndClientUserRole = "matchfinEndClientUser";
export const b2cEndclientUser = "b2cEndclientUser";
export const analystRole = "analyst";
export const producerRole = "producer";
export const headOfProducersRole = "headOfProducers";
export const investorRole = "investor";
export const salesRole = "sales";
export const salesManagerRole = "salesManager";
export const complianceOfficerRole = "complianceOfficer";
export const backOfficeRole = "backOffice";
export const traderRole = "trader";
export const portfolioManagerRole = "portfolioManager";
export const guestSgrRole = "guestSgr";
export const guestBankRole = "guestBank";
// this role is needed to be able to generate a different url for those users that are not yet approved
export const tenantRealmPendingApprovalRole = "tenantRealmPendingApproval";
export const pendingApprovalRole = "pendingApproval";
export const matchfinAnalystRole = "matchfinAnalyst";
export const matchfinSalesRole = "matchfinSales";
export const canFilterbyAlycInvestorRole = "canFilterbyAlycInvestor";
