/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import { canQueryLufe } from "./permissions.ts";

const lufePermissions: PermissionCollection = {
  namespace: "lines",
  permissions: [
    {
      name: canQueryLufe,
      description: "Puede consultar la información de LUFE",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.sales,
        Roles.salesManager,
        Roles.portfolioManager,
        Roles.complianceOfficer,
      ],
    },
  ],
};

export default lufePermissions;
