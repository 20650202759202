/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import { canCreateRiskAnalysisPaymentOrder } from "./permissions.ts";

const riskAnalysisPermissions: PermissionCollection = {
  namespace: "riskAnalysis",
  permissions: [
    {
      name: canCreateRiskAnalysisPaymentOrder,
      description:
        "Puede crear una órden de pago para el análisis de riesgo b2c",
      roles: [Roles.matchfinEndClientUser, Roles.b2cEndclientUser],
    },
  ],
};

export default riskAnalysisPermissions;
