/* DON'T EDIT THIS FILE: edit original and run build again */ import { FirestoreDocumentData } from "../../framework/firebase/firestore-wrappers-types.ts";

export enum Roles {
  admin = "admin",
  tenantAdmin = "tenantAdmin",
  endClientUser = "endClientUser",
  matchfinEndClientUser = "matchfinEndClientUser",
  b2cEndclientUser = "b2cEndclientUser",
  analyst = "analyst",
  producer = "producer",
  headOfProducers = "headOfProducers",
  investor = "investor",
  sales = "sales",
  salesManager = "salesManager",
  complianceOfficer = "complianceOfficer",
  backOffice = "backOffice",
  trader = "trader",
  portfolioManager = "portfolioManager",
  guestSgr = "guestSgr",
  guestBank = "guestBank",
  tenantRealmPendingApproval = "tenantRealmPendingApproval",
  pendingApproval = "pendingApproval",
  matchfinAnalyst = "matchfinAnalyst",
  matchfinSales = "matchfinSales",
  canFilterbyAlycInvestor = "canFilterbyAlycInvestor",
}

export type RolesMap = Partial<{
  [role in `${Roles}`]: true;
}>;

export type UserRoles = FirestoreDocumentData<{
  roles: Roles[];
  tenant: string;
  disabled: boolean;
}>;
