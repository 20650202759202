/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentHeaderParams } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseSgrOnboardingOptions } from "../../../framework/tenant-handlers/base-sgr-onboarding-options.ts";
import { ComponentType } from "react";

class SgrOnboardingOptions extends BaseSgrOnboardingOptions {
  async getDocumentHeader(): Promise<ComponentType<DocumentHeaderParams>> {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const sgrOnboardingOptions = new SgrOnboardingOptions();
