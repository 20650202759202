/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canCreateOrderRouting,
  canCreateOrders,
  canCreatePreorders,
  canEditAnyOrderRouting,
  canEditAnySentOrderRouting,
  canEditAnyUnderReviewOrderRouting,
  canEditCreatedOrderRouting,
  canEditOrderRouting,
  canListEndClientPrincipalAccounts,
  canListPartyPrincipalAccounts,
  canListTenantPrincipalAccounts,
  canReadPrimaryData,
  canRecreateAnyOrderRouting,
  canRecreateCreatedOrderRouting,
  canTraderTakeOrderRouting,
  canViewOrderRouting,
  hasOrderRoutingEndClientViewAccess,
  hasOrderRoutingPartyViewAccess,
  hasOrderRoutingTenantViewAccess,
  hasOrderRoutingTraderViewAccess,
} from "./permissions.ts";

const ordersPermissions: PermissionCollection = {
  namespace: "orders",
  permissions: [
    {
      name: canListTenantPrincipalAccounts,
      description:
        "Puede, a la hora de crear una orden/pre-orden, listar las princpales cuentas cargadas en el tenant",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.complianceOfficer,
      ],
    },
    {
      name: canListPartyPrincipalAccounts,
      description:
        "Puede, a la hora de crear una orden, pre-orden, listar las principales cuentas asociadas al comercial o productor asociados",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },

    {
      name: canListEndClientPrincipalAccounts,
      description:
        "Puede, a la hora de crear una orden/pre-orden, listar las principales cuentas cargadas por el/los end clients",
      roles: [Roles.endClientUser],
    },

    {
      name: canCreateOrderRouting,
      description: "Puede acceder y crear nuevas ordenes y/o pre-ordenes",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },
    {
      name: canCreatePreorders,
      description: "Puede crear nuevas pre-ordenes",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
      ],
    },
    {
      name: canCreateOrders,
      description: "Puede crear nuevas ordenes",
      roles: [Roles.endClientUser],
    },

    {
      name: canEditOrderRouting,
      description: "Puede editar ordenes creadas",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },
    {
      name: canEditAnyOrderRouting,
      description:
        "Puede editar cualquier order, independientemente de en que estado se encuentre",
      roles: [Roles.admin],
    },

    {
      name: canEditAnyUnderReviewOrderRouting,
      description: "Puede editar ordenes en estado En Revisión,",
      roles: [Roles.backOffice],
    },
    {
      name: canEditCreatedOrderRouting,
      description: "Puede editar ordenes creadas por si mismo",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },
    {
      name: canEditAnySentOrderRouting,
      description: "Puede editar ordenes en estado Enviada a la mesa",
      roles: [Roles.trader],
    },

    {
      name: canRecreateAnyOrderRouting,
      description:
        "Puede recrear cualquier orden/pre-orden que estén en estado Revisar o Parcialmente Operada",
      roles: [Roles.admin],
    },
    {
      name: canRecreateCreatedOrderRouting,
      description:
        "Puede recrear ordenes/pre-ordenees que estén en estado Revisar o Parcialmente Operada y de la cual el usuario sea el creador",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },

    {
      name: canTraderTakeOrderRouting,
      description: "Puede recibir una order/pre-orden como Trader",
      roles: [Roles.trader],
    },

    {
      name: canViewOrderRouting,
      description: "Puede ver la sección Órdenes",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.complianceOfficer,
      ],
    },

    {
      name: hasOrderRoutingTenantViewAccess,
      description:
        "Tiene acceso a las órdenes/pre-ordenes por ser parte del mismo tenant",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.backOffice,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.complianceOfficer,
      ],
    },

    {
      name: hasOrderRoutingTraderViewAccess,
      description:
        "Puede acceder a las ordenes/pre-ordenes por tener rol Trader",
      roles: [Roles.trader],
    },
    {
      name: hasOrderRoutingPartyViewAccess,
      description:
        "Puede acceder a la información de la órden por ser alguna de las partes de la órden (compradora/vendedora)",
      roles: [
        Roles.producer,
        Roles.headOfProducers,
        Roles.sales,
        Roles.salesManager,
      ],
    },

    {
      name: hasOrderRoutingEndClientViewAccess,
      description:
        "Puede acceder a la información de la órden/pre-órden por ser End Client de la misma",
      roles: [Roles.endClientUser],
    },
    {
      name: canReadPrimaryData,
      description: "Puede acceder a la información provista por Primary",
      roles: [
        Roles.admin,
        Roles.tenantAdmin,
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },
  ],
};

export default ordersPermissions;
