/* DON'T EDIT THIS FILE: edit original and run build again */ export const canEditBaseFolderAssignee = "canEditAssignee";
export const canEditBaseFolderProducer = "canEditProducer";
export const canDeleteBaseFolderAssignee = "canDeleteBaseFolderAssignee";
export const canDeleteBaseFolderProducer = "canDeleteBaseFolderProducer";
export const canAccessBaseFolders = "canAccessBaseFolders";
export const canAccessOtherUsersFolders = "canAccessOtherUsersFolders";
export const canAccessOtherProducersFolders = "canAccessOtherProducersFolders";
export const canAccessFoldersSharedWithMfAnalysts =
  "canAccessFoldersSharedWithMfAnalysts";
export const canAccessFoldersSharedWithInternalAnalysts =
  "canAccessFoldersSharedWithInternalAnalysts";
  export const canBeAssignedAsProducerUid = "canBeAssignedAsProducerUid";
  export const canBeAssignedAsAssigneeUid = "canBeAssignedAsAssigneeUid";
