/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import { canViewLiveRisk } from "./permissions.ts";

const liveRiskPermissions: PermissionCollection = {
  namespace: "liveRisk",
  permissions: [
    {
      name: canViewLiveRisk,
      description:
        "Puede ver el riesgo vivo en la vista presentación de una carpeta.",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.matchfinEndClientUser,
        Roles.b2cEndclientUser,
        Roles.portfolioManager,
        Roles.complianceOfficer,
      ],
    },
  ],
};

export default liveRiskPermissions;
