/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessOtherProducersLineSharings,
  canAccessOtherUsersLineSharings,
  canEditLineSharings,
  canViewGrantedLineSharings,
} from "./permissions.ts";

const lineSharingPermissions: PermissionCollection = {
  namespace: "lineSharing",
  permissions: [
    {
      name: canEditLineSharings,
      description: "Puede editar lo compartido de las carpetas",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.analyst,
      ],
    },
    {
      name: canViewGrantedLineSharings,
      description: "Puede ver las carpetas compartidas dentro del tenant",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.matchfinEndClientUser,
        Roles.b2cEndclientUser,
        Roles.portfolioManager,
      ],
    },
    {
      name: canAccessOtherUsersLineSharings,
      description:
        "Puede accerder a las carpetas compartidas de otros usuarios",
      roles: [
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.salesManager,
        Roles.portfolioManager,
      ],
    },
    {
      name: canAccessOtherProducersLineSharings,
      description:
        " Puede acceder a las carpetas compartidas de otros usuarios con rol productor",
      roles: [Roles.headOfProducers],
    },
  ],
};

export default lineSharingPermissions;
