/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canApproveAssignedFoldersEndClientAccessRequests,
  canApproveAssignedFoldersEndClientRoleRequests,
  canApproveEndClientAccessRequests,
  canApproveEndClientRoleRequests,
  canApproveProducerFolderEndClientAccessRequests,
  canApproveProducerFolderEndClientRoleRequests,
  canApproveRoleRequests,
} from "./permissions.ts";

const approvalUsersPermissions: PermissionCollection = {
  namespace: "users",
  permissions: [
    {
      name: canApproveRoleRequests,
      description: "Puede aprobar pedidos de roles",
      roles: [Roles.admin],
    },

    {
      name: canApproveEndClientRoleRequests,
      description: "Puede aprobar pedidos de rol end client",
      roles: [Roles.admin, Roles.tenantAdmin, Roles.salesManager],
    },

    {
      name: canApproveProducerFolderEndClientRoleRequests,
      description:
        "Puede otorgar el acceso de end client de carpeta de productores",
      roles: [Roles.headOfProducers],
    },

    {
      name: canApproveAssignedFoldersEndClientRoleRequests,
      description: "Puede otorgar el rol de end client de carpeta de comercial",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
    {
      name: canApproveEndClientAccessRequests,
      description: "Puede aprobar el acceso a end clients",
      roles: [Roles.admin, Roles.tenantAdmin, Roles.salesManager],
    },
    {
      name: canApproveProducerFolderEndClientAccessRequests,
      description: "Puede dar acceso a end client de carpeta de productores",
      roles: [Roles.headOfProducers],
    },
    {
      name: canApproveAssignedFoldersEndClientAccessRequests,
      description: "Puede dar acceso a end client de carpeta de comercial",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
  ],
};

export default approvalUsersPermissions;
