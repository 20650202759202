/* DON'T EDIT THIS FILE: edit original and run build again */ import { canQueryLufe } from "../../credit/line-permissions/permissions.ts";
import { lufeListingPath } from "../../credit/lufe-paths/lufe-listing-path.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { SidebarMenuItem } from "../../framework/sidebar-type/sidebar-types.ts";
import { SgrTenantHandler } from "../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { LufeIcon } from "../../framework/theme-icons/icon.ts";

export const lufeListingTabId = "lufe";

const folderMenuItem: SidebarMenuItem = {
  id: lufeListingTabId,
  getPath: () => lufeListingPath,
  label: "Lufe",
  condition: ({ tenantHandler, roles }) => {
    const hasLufe =
      tenantHandler instanceof SgrTenantHandler && tenantHandler.hasLufe();
    return hasLufe && userHasPermission(roles, canQueryLufe);
  },
  order: 11,
  hasPriority: true,
  icon: (className) => <LufeIcon className={className} />,
};

export default folderMenuItem;
