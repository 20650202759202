/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canListLinesWithCommonStatuses,
  canListLinesWithDataLoadedStatus,
  canListLinesWithInfoRequestStatus,
  canListLinesWithInitialLoadStatus,
  canListLinesWithPresentationStatus,
  canViewLinesWithCommonStatuses,
  canViewLinesWithDataLoadedStatus,
  canViewLinesWithInfoRequestStatus,
  canViewLinesWithInitialLoadStatus,
  canViewLinesWithPresentationStatus,
} from "./permissions.ts";

const linesStatusPermissions: PermissionCollection = {
  namespace: "lines",
  permissions: [
    {
      name: canListLinesWithCommonStatuses,
      description:
        "Puede listar carpetas con estados comunes: surveying, analysis, onboardingStatus, approved, rejected, loadReview, riskReview, matchfinAnalystsReview",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },
    {
      name: canViewLinesWithCommonStatuses,
      description:
        "Puede ver carpetas en estados comunes: surveying, analysis, onboardingStatus, approved, rejected, loadReview, riskReview, matchfinAnalystsReview",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.endClientUser,
      ],
    },
    {
      name: canListLinesWithPresentationStatus,
      description: "Puede listar carpetas en estado presentación",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },
    {
      name: canViewLinesWithPresentationStatus,
      description: "Puede ver carpetas en estado presentación",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.endClientUser,
        Roles.b2cEndclientUser,
        Roles.matchfinEndClientUser,
      ],
    },
    {
      name: canListLinesWithInfoRequestStatus,
      description: "Puede listar carpetas con estado Información solicitada",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },

    {
      name: canViewLinesWithInfoRequestStatus,
      description: "Puede ver carpetas con estado Información solicitada",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.endClientUser,
        Roles.b2cEndclientUser,
        Roles.matchfinEndClientUser,
      ],
    },

    {
      name: canListLinesWithInitialLoadStatus,
      description: "Puede listar carpetas con estado Carga en Proceso",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },

    {
      name: canViewLinesWithInitialLoadStatus,
      description: "Puede ver carpetas con estado Carga en Proceso",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.endClientUser,
        Roles.b2cEndclientUser,
        Roles.matchfinEndClientUser,
      ],
    },

    {
      name: canListLinesWithDataLoadedStatus,
      description: "Puede listar carpetas con estado Carga Completa",
      roles: [Roles.admin, Roles.matchfinAnalyst],
    },

    {
      name: canViewLinesWithDataLoadedStatus,
      description: "Puede ver carpetas con estado Carga Completa",
      roles: [
        Roles.admin,
        Roles.matchfinAnalyst,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.b2cEndclientUser,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.salesManager,
        Roles.headOfProducers,
        Roles.matchfinEndClientUser,
        Roles.portfolioManager,
        Roles.producer,
        Roles.sales,
        Roles.tenantAdmin,
      ],
    },
  ],
};

export default linesStatusPermissions;
