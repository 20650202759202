// DON'T EDIT THIS FILE: edit the original Triggers.ts files and regenerate derived files

import hook0 from "../credit/assigned-entities-permissions/permission-collect.ts";
import hook1 from "../credit/base-folder-permissions/permission-collect.ts";
import hook2 from "../credit/common-section-permissions/permission-collect.ts";
import hook3 from "../credit/control-panel-permissions/permission-collect.ts";
import hook4 from "../credit/line-analysis-permissions/permission-collect.ts";
import hook5 from "../credit/line-inbox-permissions/permission-collect.ts";
import hook6 from "../credit/line-permissions/permission-collect.ts";
import hook7 from "../credit/line-permissions/status-permission-collect.ts";
import hook8 from "../credit/line-permissions/edit-permission-collect.ts";
import hook9 from "../credit/line-permissions/view-permission-collect.ts";
import hook10 from "../credit/line-permissions/access-permission-collect.ts";
import hook11 from "../credit/line-permissions/lufe-permission-collect.ts";
import hook12 from "../credit/line-permissions/live-risk-permission-collect.ts";
import hook13 from "../credit/line-sharing-permissions/permission-collect.ts";
import hook14 from "../credit/marketplace-permissions/permission-collect.ts";
import hook15 from "../credit/quant-permissions/permission-collect.ts";
import hook16 from "../credit/risk-analysis-permissions/permission-collect.ts";
import hook17 from "../credit/risk-matrix-permissions/permission-collect.ts";
import hook18 from "../credit/sgr-reports-permissions/permission-collect.ts";
import hook19 from "../onboarding/onboarding-permissions/permission-collect.ts";
import hook20 from "../onboarding/onboarding-permissions/red-link-permission-collect.ts";
import hook21 from "../onboarding/onboarding-permissions/aune-permission-collect.ts";
import hook22 from "../onboarding/onboarding-permissions/esco-permission-collect.ts";
import hook23 from "../onboarding/onboarding-permissions/renaper-permission-collect.ts";
import hook24 from "../orders/order-permissions/permission-collect.ts";
import hook25 from "../orders/order-permissions/status-permission-collect.ts";
import hook26 from "../framework/chat-permissions/permission-collect.ts";
import hook27 from "../framework/counterparties-permissions/permission-collect.ts";
import hook28 from "../framework/helpers-permissions/permission-collect.ts";
import hook29 from "../framework/pages-permissions/permission-collect.ts";
import hook30 from "../framework/tenant-permissions/permission-collect.ts";
import hook31 from "../framework/users-permissions/permission-collect.ts";
import hook32 from "../framework/users-permissions/approval-permission-collect.ts";

export default [hook0, hook1, hook2, hook3, hook4, hook5, hook6, hook7, hook8, hook9, hook10, hook11, hook12, hook13, hook14, hook15, hook16, hook17, hook18, hook19, hook20, hook21, hook22, hook23, hook24, hook25, hook26, hook27, hook28, hook29, hook30, hook31, hook32];
