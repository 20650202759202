/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import { canPublishToAune } from "./permissions.ts";

const aunePermissions: PermissionCollection = {
  namespace: "onboarding",
  permissions: [
    {
      name: canPublishToAune,
      description: "Puede publicar en AUNE",
      roles: [Roles.tenantAdmin, Roles.admin, Roles.complianceOfficer],
    },
  ],
};

export default aunePermissions;
