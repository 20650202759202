/* DON'T EDIT THIS FILE: edit original and run build again */ // This file can be regenerated by running m-f build-theme-icons, don't edit

import { createGenericIcon } from "../../framework/theme-icons/generic-icon.tsx";

export const AddCircleOutlineIcon = createGenericIcon("AddCircleOutlineIcon");
export const ApartmentIcon = createGenericIcon("ApartmentIcon");
export const AssignmentIcon = createGenericIcon("AssignmentIcon");
export const AssignmentLateIcon = createGenericIcon("AssignmentLateIcon");
export const AssignmentTurnedInIcon = createGenericIcon("AssignmentTurnedInIcon");
export const AsteriskIcon = createGenericIcon("AsteriskIcon");
export const BackIcon = createGenericIcon("BackIcon");
export const BellIcon = createGenericIcon("BellIcon");
export const BoldIcon = createGenericIcon("BoldIcon");
export const BriefcaseIcon = createGenericIcon("BriefcaseIcon");
export const BuildingIcon = createGenericIcon("BuildingIcon");
export const CarIcon = createGenericIcon("CarIcon");
export const ChatIcon = createGenericIcon("ChatIcon");
export const ChatsIcon = createGenericIcon("ChatsIcon");
export const CheckIcon = createGenericIcon("CheckIcon");
export const ChevronBottomIcon = createGenericIcon("ChevronBottomIcon");
export const ChevronLeftIcon = createGenericIcon("ChevronLeftIcon");
export const ChevronRightIcon = createGenericIcon("ChevronRightIcon");
export const CircleCheckOutlineIcon = createGenericIcon("CircleCheckOutlineIcon");
export const ControlPointDuplicateIcon = createGenericIcon("ControlPointDuplicateIcon");
export const CopyIcon = createGenericIcon("CopyIcon");
export const CutIcon = createGenericIcon("CutIcon");
export const DateRangeIcon = createGenericIcon("DateRangeIcon");
export const DeleteForeverIcon = createGenericIcon("DeleteForeverIcon");
export const DocumentsLargeIcon = createGenericIcon("DocumentsLargeIcon");
export const EditFileIcon = createGenericIcon("EditFileIcon");
export const ExitToAppIcon = createGenericIcon("ExitToAppIcon");
export const EyeIcon = createGenericIcon("EyeIcon");
export const EyeOffIcon = createGenericIcon("EyeOffIcon");
export const FileBlankOutlineIcon = createGenericIcon("FileBlankOutlineIcon");
export const FilePresentIcon = createGenericIcon("FilePresentIcon");
export const FolderIcon = createGenericIcon("FolderIcon");
export const GearIcon = createGenericIcon("GearIcon");
export const GuaranteeIcon = createGenericIcon("GuaranteeIcon");
export const HamburgerIcon = createGenericIcon("HamburgerIcon");
export const HeadingH1Icon = createGenericIcon("HeadingH1Icon");
export const HeadingH2Icon = createGenericIcon("HeadingH2Icon");
export const HeadingH3Icon = createGenericIcon("HeadingH3Icon");
export const HomeIcon = createGenericIcon("HomeIcon");
export const HouseIcon = createGenericIcon("HouseIcon");
export const ImageIcon = createGenericIcon("ImageIcon");
export const InfoCircleOutlineIcon = createGenericIcon("InfoCircleOutlineIcon");
export const ItalicIcon = createGenericIcon("ItalicIcon");
export const LinkIcon = createGenericIcon("LinkIcon");
export const ListUlIcon = createGenericIcon("ListUlIcon");
export const LogoAvaluarSmallIcon = createGenericIcon("LogoAvaluarSmallIcon");
export const LogoAwaSmallIcon = createGenericIcon("LogoAwaSmallIcon");
export const LogoBetaSmallIcon = createGenericIcon("LogoBetaSmallIcon");
export const LogoBrioSmallIcon = createGenericIcon("LogoBrioSmallIcon");
export const LogoElycaSmallIcon = createGenericIcon("LogoElycaSmallIcon");
export const LogoFirstcapitalSmallIcon = createGenericIcon("LogoFirstcapitalSmallIcon");
export const LogoGlobalmarketSmallIcon = createGenericIcon("LogoGlobalmarketSmallIcon");
export const LogoInverstartSmallIcon = createGenericIcon("LogoInverstartSmallIcon");
export const LogoListroSmallIcon = createGenericIcon("LogoListroSmallIcon");
export const LogoMatchfinColoredIcon = createGenericIcon("LogoMatchfinColoredIcon");
export const LogoMatchfinSmallIcon = createGenericIcon("LogoMatchfinSmallIcon");
export const LogoMediterraneaSmallIcon = createGenericIcon("LogoMediterraneaSmallIcon");
export const LogoMultiploSmallIcon = createGenericIcon("LogoMultiploSmallIcon");
export const LogoReyleyesvaloresSmallIcon = createGenericIcon("LogoReyleyesvaloresSmallIcon");
export const LogoRosvalSmallIcon = createGenericIcon("LogoRosvalSmallIcon");
export const LogoStcSmallIcon = createGenericIcon("LogoStcSmallIcon");
export const LogoUgvaloresSmallIcon = createGenericIcon("LogoUgvaloresSmallIcon");
export const LogoZofingenSmallIcon = createGenericIcon("LogoZofingenSmallIcon");
export const LufeIcon = createGenericIcon("LufeIcon");
export const MagnifyingGlassIcon = createGenericIcon("MagnifyingGlassIcon");
export const MailOutlineIcon = createGenericIcon("MailOutlineIcon");
export const MatrixIcon = createGenericIcon("MatrixIcon");
export const OffOutlineCloseIcon = createGenericIcon("OffOutlineCloseIcon");
export const OnboardingIcon = createGenericIcon("OnboardingIcon");
export const PaperPlaneRightIcon = createGenericIcon("PaperPlaneRightIcon");
export const PasteIcon = createGenericIcon("PasteIcon");
export const PhoneIcon = createGenericIcon("PhoneIcon");
export const PhoneOutlineIcon = createGenericIcon("PhoneOutlineIcon");
export const PlayIcon = createGenericIcon("PlayIcon");
export const PowerIcon = createGenericIcon("PowerIcon");
export const ProjectorScreenIcon = createGenericIcon("ProjectorScreenIcon");
export const QuestionMarkCircleIcon = createGenericIcon("QuestionMarkCircleIcon");
export const RedoIcon = createGenericIcon("RedoIcon");
export const ReportIcon = createGenericIcon("ReportIcon");
export const ShareIcon = createGenericIcon("ShareIcon");
export const StackedLineChartIcon = createGenericIcon("StackedLineChartIcon");
export const SupervisorAccountIcon = createGenericIcon("SupervisorAccountIcon");
export const TableAddIcon = createGenericIcon("TableAddIcon");
export const TurnMobileIcon = createGenericIcon("TurnMobileIcon");
export const UnderlineIcon = createGenericIcon("UnderlineIcon");
export const UndoIcon = createGenericIcon("UndoIcon");
export const UnlinkIcon = createGenericIcon("UnlinkIcon");
export const UserIcon = createGenericIcon("UserIcon");
export const UsersIcon = createGenericIcon("UsersIcon");
export const WarningOutlineIcon = createGenericIcon("WarningOutlineIcon");
export const WhatsappIcon = createGenericIcon("WhatsappIcon");
export const WhatsappPlainIcon = createGenericIcon("WhatsappPlainIcon");
export const XIcon = createGenericIcon("XIcon");
