/* DON'T EDIT THIS FILE: edit original and run build again */ import { getEntityId } from "../../credit/entity/get-entity-id.ts";
import { isCompanyCuit } from "../../credit/gov-id-number/cuit.ts";
import { EntityType } from "../../credit/gov-id-number/entity-type.ts";
import { DotPath, pathGet } from "../../framework/dotpath/path.ts";
import {
  GetDoc,
  GetDocId,
  SaveMode,
  updateDocOrObjectProps,
} from "../../framework/firebase/update-doc-or-map.ts";
import deepEqual from "fast-deep-equal";
import { InlineEntity } from "./inline-entity-type.ts";

const getInlineEntitiesPath = () => new DotPath("inlineEntities");

export const getInlineEntityPath = (entityId: string) =>
  getInlineEntitiesPath().child(entityId);

const getInlineEntities = (docData: Record<string, any>): InlineEntity[] =>
  Object.values(pathGet(docData, getInlineEntitiesPath(), {}));

const getInlineEntityByForeignIdNumber = (
  docData: Record<string, any>,
  country: string,
  foreignIdNumber: string
): InlineEntity | null =>
  getInlineEntities(docData).find(
    ({ country: personCountry, foreignIdNumber: personForeignIdNumber }) =>
      country === personCountry && foreignIdNumber === personForeignIdNumber
  ) ?? null;

export const getInlineEntityByCuit = (
  docData: any,
  cuit: string
): InlineEntity | null =>
  getInlineEntities(docData).find(
    ({ cuit: personCuit }) => cuit === personCuit
  ) ?? null;

const getInlineEntityByDni = (docData: any, dni: string): InlineEntity | null =>
  getInlineEntities(docData).find(({ dni: personDni }) => dni === personDni) ??
  null;

export const getInlineEntityByIdOrNull = (
  docData: any,
  entityId: string
): InlineEntity | null =>
  pathGet(docData, getInlineEntityPath(entityId)) ?? null;

export const getInlineEntityById = (
  docData: any,
  entityId: string
): InlineEntity => {
  const entity = getInlineEntityByIdOrNull(docData, entityId);
  if (!entity) {
    throw new Error("missingInlineEntity: " + entityId);
  }
  return entity;
};

const getAddInlineEntityUpdate = (inlineEntity: InlineEntity) => {
  return {
    [getInlineEntityPath(inlineEntity.id).toString()]: inlineEntity,
  };
};

const addInlineEntity = ({
  docData,
  value,
  mode = SaveMode.SaveToDatabase,
  getDoc,
  getDocId,
}: {
  docData: any;
  value: Partial<InlineEntity>;
  mode?: SaveMode;
  getDoc: (id: string) => any;
  getDocId: (docData: any) => string;
}): InlineEntity | Promise<InlineEntity> => {
  const valueToSave = { ...value, id: getEntityId(value) };
  const update = getAddInlineEntityUpdate(valueToSave);
  const tmp = updateDocOrObjectProps(docData, update, getDoc, mode, getDocId);
  if (tmp instanceof Promise) {
    // in this case, we need to wait for the update to finish, otherwise we don't need to be async
    return tmp.then(() => valueToSave);
  }
  return valueToSave;
};

export const isCompanyInlineEntity = ({
  cuit,
  isCompany,
}: {
  cuit?: string;
  isCompany?: boolean;
}) => {
  if (cuit) {
    return isCompanyCuit(cuit);
  } else {
    return isCompany ?? false;
  }
};

const updateInlineEntity = ({
  docData,
  value: { id, ...valueWithoutId },
  mode,
  getDoc,
  getDocId,
}: {
  docData: any;
  value: any;
  mode: SaveMode;
  getDoc: GetDoc;
  getDocId: GetDocId;
}) => {
  if (!id) {
    throw new Error("missingIdToUpdate");
  }
  const prevValue = getInlineEntityByIdOrNull(docData, id);
  if (!prevValue) {
    throw new Error("cannotUpdateNonExisting: " + id);
  }
  const update: Record<string, any> = {};
  for (const [prop, propValue] of Object.entries(valueWithoutId)) {
    // @ts-expect-error - this is a dynamic property access
    if (prevValue[prop] !== propValue) {
      update[getInlineEntityPath(id).child(prop).toString()] = propValue;
    }
  }
  return updateDocOrObjectProps(docData, update, getDoc, mode, getDocId);
};

export const findInlineEntityByRecord = (
  docData: Record<string, any>,
  {
    cuit,
    dni,
    country,
    foreignIdNumber,
  }: { cuit?: string; dni?: string; country?: string; foreignIdNumber?: string }
): InlineEntity | null => {
  if (cuit) {
    return getInlineEntityByCuit(docData, cuit);
  } else if (dni) {
    return getInlineEntityByDni(docData, dni);
  } else if (country && foreignIdNumber) {
    return getInlineEntityByForeignIdNumber(docData, country, foreignIdNumber);
  } else {
    throw new Error("missingKeyInfoForId");
  }
};

export function upsertInlineEntityToOneDoc({
  docData,
  value,
  mode = SaveMode.SaveToDatabase,
  getDoc,
  getDocId = () => docData.id,
}: {
  docData: any;
  value: Partial<InlineEntity>;
  mode: SaveMode;
  getDoc: GetDoc;
  getDocId: GetDocId;
}): InlineEntity | Promise<InlineEntity> {
  const existingEntity = findInlineEntityByRecord(docData, value);
  if (existingEntity) {
    let additional = {};
    if ((value.firstName || value.lastName) && !isCompanyInlineEntity(value)) {
      additional = { entityName: value.firstName + " " + value.lastName };
    }
    const entityToSave = {
      id: existingEntity.id,
      ...value,
      ...additional,
    };
    if (!deepEqual(existingEntity, entityToSave)) {
      const tmp = updateInlineEntity({
        docData,
        value: entityToSave,
        mode,
        getDoc,
        getDocId,
      });
      if (tmp instanceof Promise) {
        // in this case, we need to wait for the update to finish, otherwise we don't need to be async
        return tmp.then(() => entityToSave);
      }
    } else if (mode === SaveMode.SaveToDatabase) {
      return Promise.resolve(entityToSave);
    }
    return entityToSave;
  } else {
    return addInlineEntity({
      docData,
      value,
      mode,
      getDoc,
      getDocId,
    });
  }
}

export const isInlineEntityEmpty = (
  value: Partial<InlineEntity> | undefined | null
) => {
  if (!value) {
    return true;
  }
  const {
    foreignIdNumber,
    country,
    isCompany,
    entityName,
    firstName,
    lastName,
    cuit,
  } = value;
  if (cuit) {
    return false;
  }
  if (isCompany) {
    return !foreignIdNumber && !country && !entityName;
  } else {
    return !foreignIdNumber && !country && !firstName && !lastName;
  }
};

export const getInlineEntityError = (
  {
    foreignIdNumber,
    country,
    isCompany,
    entityName,
    firstName,
    lastName,
    cuit,
  }: Partial<InlineEntity>,
  {
    allowEntityTypes,
    required,
  }: { allowEntityTypes?: EntityType; required?: boolean } = {
    allowEntityTypes: EntityType.Any,
    required: false,
  }
) => {
  if (!cuit && !foreignIdNumber && !required) {
    return null;
  }
  if (!cuit) {
    if (!foreignIdNumber) {
      return "El número de identificación es obligatorio";
    }
    if (!country) {
      return "El país es obligatorio";
    }
    if (isCompany) {
      if (!entityName) {
        return "La razón social es obligatoria";
      }
    } else {
      if (!firstName) {
        return "El nombre es obligatorio";
      }
      if (!lastName) {
        return "El apellido es obligatorio";
      }
    }
  } else {
    if (!cuit) {
      return "El cuit es obligatorio";
    }
  }
  const companyCuit = isCompanyInlineEntity({ cuit, isCompany });
  if (allowEntityTypes === EntityType.OnlyHumans && companyCuit) {
    return "Sólo se pueden indicar personas humanas";
  }
  if (allowEntityTypes === EntityType.OnlyCompanies && !companyCuit) {
    return "Sólo se pueden indicar empresas";
  }
  return null;
};

export const getMainInlineEntity = (
  docData: Record<string, any>
): InlineEntity => getInlineEntityById(docData, docData.entityId);

export const isMainInlineEntity = (
  baseFolderId: string,
  entity: InlineEntity
) => baseFolderId === entity.id;
