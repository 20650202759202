/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canResolveAnyActiveOrderRoutingStatus,
  canResolveAnyModifiedOrderRoutingStatus,
  canResolveAnyUnfinishedOrderRoutingStatus,
  canResolveCreatedModifiedOrderRoutingStatus,
  canResolveCreatedUnfinishedOrderRoutingStatus,
  canResolveNonTakenActiveOrderRoutingStatus,
  canResolvePendingOrderRoutingStatus,
  canResolveSentOrderRoutingStatus,
  canResolveUnderReviewOrderRoutingStatus,
  canSetActiveOrderRoutingStatus,
  canSetAnnulledCreatedOrderRoutingStatus,
  canSetAnyOrderRoutingStatus,
  canSetDiscardedOrderRoutingStatus,
  canSetOperatedOrderRoutingStatus,
  canSetUnderReviewOrderRoutingStatus,
} from "./permissions.ts";

const ordersStatusPermissions: PermissionCollection = {
  namespace: "orders",
  permissions: [
    {
      name: canSetAnyOrderRoutingStatus,
      description: "Puede modificar el estado de cualquier orden/pre-orden",
      roles: [Roles.admin],
    },

    {
      name: canSetUnderReviewOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden a Bajo Revisión",
      roles: [Roles.backOffice],
    },
    {
      name: canSetAnnulledCreatedOrderRoutingStatus,
      description: "Puede modificar el estado de una orden/pre-orden a Anulada",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.endClientUser,
        Roles.backOffice,
        Roles.trader,
      ],
    },
    {
      name: canSetActiveOrderRoutingStatus,
      description: "Puede modificar el estado de una orden/pre-orden a Activa",
      roles: [Roles.trader],
    },

    {
      name: canSetDiscardedOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden a Descartada",
      roles: [Roles.backOffice],
    },

    {
      name: canSetOperatedOrderRoutingStatus,
      description: "Puede modificar el estado de una orden/pre-orden a Operada",
      roles: [Roles.trader],
    },
    {
      name: canResolveAnyModifiedOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden en el estado Modifcada",
      roles: [Roles.admin],
    },
    {
      name: canResolveCreatedModifiedOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden en el estado Modificada y que el usuario haya creado",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.endClientUser,
        Roles.backOffice,
        Roles.trader,
      ],
    },
    {
      name: canResolvePendingOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden en el estado Pendiente",
      roles: [Roles.backOffice],
    },
    {
      name: canResolveUnderReviewOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden en el estado Bajo Revisión",
      roles: [Roles.backOffice],
    },
    {
      name: canResolveSentOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden en el estado Enviada a la mesa",
      roles: [Roles.trader],
    },
    {
      name: canResolveAnyActiveOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden en el estado Activa",
      roles: [Roles.admin],
    },
    {
      name: canResolveNonTakenActiveOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden en el estado Activa y que no esté asignada a un trader",
      roles: [Roles.trader],
    },
    {
      name: canResolveAnyUnfinishedOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden en el estado No concluída",
      roles: [Roles.admin],
    },
    {
      name: canResolveCreatedUnfinishedOrderRoutingStatus,
      description:
        "Puede modificar el estado de una orden/pre-orden en el estado No concluída y que el usuario haya creado",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },
  ],
};

export default ordersStatusPermissions;
