/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentHeaderParams,
  TermsOfUseParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseSgrOnboardingOptions } from "../../../framework/tenant-handlers/base-sgr-onboarding-options.ts";
import { ComponentImporter } from "../../../framework/theme/component-importer-type.ts";
import {
  emailChannelType,
  reutersChannelType,
  whatsappChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import { ComponentType } from "react";
import { onboardingTermsOfUseTitle } from "./onboarding-terms-of-use-title.ts";

class FintechsgrOnboardingOptions extends BaseSgrOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  termsOfUseImporter: ComponentImporter<TermsOfUseParams> = () => {
    return import("./onboarding-terms-of-use.tsx");
  };

  getSupportedChannelTypes(): string[] {
    return [reutersChannelType, whatsappChannelType, emailChannelType];
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [
      { fullName: "NOMBRE EJEMPLO", address: "ejemplo@fintechsgr.com.ar" },
    ];
  }

  async getDocumentHeader(): Promise<ComponentType<DocumentHeaderParams>> {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const fintechsgrOnboardingOptions = new FintechsgrOnboardingOptions();
