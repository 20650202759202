/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessMarketplace,
  canFilterLinesByAlyc,
  canPublishInMarketplace,
  canRequestObjectPermission,
  canViewUsersInCounterpartiesLog,
} from "./permissions.ts";

const marketplacePermissions: PermissionCollection = {
  namespace: "marketplace",
  permissions: [
    {
      name: canAccessMarketplace,
      description: "Puede acceder al Marketplace",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },

    {
      name: canViewUsersInCounterpartiesLog,
      description: "Puede ver usuarios en el historial de contrapartes",
      roles: [Roles.admin],
    },
    {
      name: canPublishInMarketplace,
      description: "Puede publicar en el Marketplace",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
    {
      name: canFilterLinesByAlyc,
      description: "Puede filtrar carpetas por Alyc",
      roles: [Roles.canFilterbyAlycInvestor],
    },
    {
      name: canRequestObjectPermission,
      description: "Permite solicitar acceso a la identidad de una carpeta",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.endClientUser,
        Roles.analyst,
      ],
    },
  ],
};

export default marketplacePermissions;
