/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import { canAccessCounterpartiesPage, canViewCounterpartiesLog } from "./permissions.ts";

const counterpartiesPermissions: PermissionCollection = {
  namespace: "counterparties",
  permissions: [
    {
      name: canAccessCounterpartiesPage,
      description: "Puede acceder a la sección Contrapartes",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
      ],
    },
    {
      name: canViewCounterpartiesLog,
      description: "Puede ver el historial de contrapartes",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.backOffice,
        Roles.trader,
        Roles.salesManager,
        Roles.headOfProducers,
      ],
    },
  ],
};

export default counterpartiesPermissions;
