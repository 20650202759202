/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentHeaderParams,
  EmailHeaderParams,
  ProofOfFciRulebookParams,
  RulebookValueOption,
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import { ComponentImporter } from "../../../framework/theme/component-importer-type.ts";
import {
  emailChannelType,
  reutersChannelType,
  webHomeChannelType,
  whatsappChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import {
  AFIP,
  EMAIL,
} from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";
import { onboardingTermsOfUseTitle } from "./onboarding-terms-of-use-title.ts";
import rulebookValueOptions from "./rulebook-value-options.ts";

class FacimexOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  termsOfUseImporter: ComponentImporter<TermsOfUseParams> = () => {
    return import("./onboarding-terms-of-use.tsx");
  };

  hasSignatura(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  hasRevenueVolume(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  hasSwornStatementAboutLegality(): boolean {
    return true;
  }

  hasProofOfFciRulebookSection(): boolean {
    return true;
  }

  hasProofOfFciRulebookContent(): boolean {
    return true;
  }

  proofOfFciRulebookContentImporter: ComponentImporter<ProofOfFciRulebookParams> =
    () => {
      return import("./proof-of-fci-rulebook.tsx");
    };

  proofOfFciRulebookAfterLabelImporter: ComponentImporter<ProofOfFciRulebookParams> =
    () => {
      return import("./proof-of-fci-rulebook-after-label.tsx");
    };

  getRulebookValueOptions(): Record<string, RulebookValueOption> {
    return rulebookValueOptions;
  }

  hasIsQualifiedInvestorText(): boolean {
    return true;
  }

  getSupportedChannelTypes(): string[] {
    return [
      reutersChannelType,
      webHomeChannelType,
      whatsappChannelType,
      emailChannelType,
    ];
  }

  hasWebHomeChannelClarificationType(): boolean {
    return true;
  }

  webHomeHeaderImporter: ComponentImporter<WebHomeHeaderParams> = () => {
    return import("./web-home-header.tsx");
  };

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [
      {
        fullName: "JOAQUIN FERNANDEZ",
        address: "joaquinfernandez@facimex.com.ar",
      },
      { fullName: "CARLOS SUAREZ", address: "carlossuarez@facimex.com.ar" },
      { fullName: "ANDRES DURBAN", address: "andresdurban@facimex.com.ar" },
      { fullName: "IGNACIO AIMI", address: "ignacioaimi@facimex.com.ar" },
    ];
  }

  emailHeaderImporter: ComponentImporter<EmailHeaderParams> = () => {
    return import("./email-header.tsx");
  };

  async getDocumentHeader(): Promise<ComponentType<DocumentHeaderParams>> {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const facimexOnboardingOptions = new FacimexOnboardingOptions();
