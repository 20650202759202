/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  accessAdminLine,
  canAccessLineAnalisis,
  canAccessLines,
  canAccessLinesPresentationView,
  canAccessLinesPresentationViewOnPresentationStatus,
  canAccessLinesPresentationViewPage,
  canAccessLinesSeparatePagesView,
  canAccessRiskPreview,
  canListLines,
} from "./permissions.ts";

const linesAccessPermissions: PermissionCollection = {
  namespace: "lines",
  permissions: [
    {
      name: canAccessLines,
      description:
        "Puede consultar carpetas a la base, puede crear carpetas, recibe notificaciones de nuevos dictámenes",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
        Roles.matchfinEndClientUser,
        Roles.b2cEndclientUser,
        Roles.portfolioManager,
      ],
    },
    {
      name: canListLines,
      description:
        "Puede acceder a carpetas, puede acceder al listado de carpetas propias",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },

    {
      name: canAccessLinesSeparatePagesView,
      description: "Puede acceder a la información completa de la carpeta",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },
    {
      name: canAccessLinesPresentationView,
      description: "Puede acceder a la presentación de la carpeta",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },

    {
      name: canAccessLinesPresentationViewPage,
      description: "Puede acceder a la vista de presentación de la carpeta",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.b2cEndclientUser,
        Roles.matchfinEndClientUser,
      ],
    },

    {
      name: canAccessLinesPresentationViewOnPresentationStatus,
      description:
        "Puede acceder a la presentación de la carpeta en estado presentación",
      roles: [Roles.matchfinEndClientUser, Roles.b2cEndclientUser],
    },
    {
      name: canAccessLineAnalisis,
      description: "Puede acceder al análisis de la carpeta",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.b2cEndclientUser,
        Roles.matchfinEndClientUser,
      ],
    },
    {
      name: accessAdminLine,
      description:
        "Puede acceder a la información para administradores de una carpeta.",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.guestBank,
        Roles.investor,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.b2cEndclientUser,
        Roles.matchfinEndClientUser,
      ],
    },
    {
      name: canAccessRiskPreview,
      description: "Puede acceder a la vista preliminar de analisis de riesgo",
      roles: [Roles.admin, Roles.matchfinEndClientUser, Roles.b2cEndclientUser],
    },
  ],
};

export default linesAccessPermissions;
