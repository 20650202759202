/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  AssignedEntitiesList,
  AssignedEntitiesListTypes,
} from "../../../credit/assigned-entities/data-pipe.ts";
import { getEndClientAccessRequestsCol } from "../../../framework/auth-access-request/access-request.ts";
import {
  EndClientAccessRequest,
  EndClientAccessRequestStatus,
} from "../../../framework/auth-access-request/schema.ts";
import {
  getProfileCol,
  getProfilesByUidPipe,
} from "../../../framework/auth-profile/profile.ts";
import { UserProfile } from "../../../framework/auth-profile/schema.ts";
import { notNull } from "../../../framework/core/not-null.ts";
import { WithId } from "../../../framework/core/with-id.ts";
import { DataPipe } from "../../../framework/data-pipe/data-pipe.ts";
import { leftJoin } from "../../../framework/data-pipe/left-join.ts";
import { batchColPipe } from "../../../framework/firebase/batch-pipe.ts";
import { colPipe } from "../../../framework/firebase/firestore-pipe.ts";
import { Roles, RolesMap } from "../../../framework/permission/schema.ts";
import { userHasPermission } from "../../../framework/permission/user-has-permission.ts";
import { canApproveEndClientAccessRequests } from "../../../framework/users-permissions/permissions.ts";
import { UsersListPipeFilters } from "../../../framework/users/filter-types.ts";
import {
  FullUserInfo,
  UsersListDataPipeArgs,
} from "../../../framework/users/list-data-pipe-params.ts";

export const createBasicEndClientAccessRequestsPipe = (
  roles: RolesMap,
  tenant: string,
  statuses: EndClientAccessRequestStatus[],
  entityIdsList: AssignedEntitiesList
): DataPipe<WithId<EndClientAccessRequest, "id">[]> => {
  const col = getEndClientAccessRequestsCol()
    .where("tenant", "==", tenant)
    .where("status", "in", statuses);
  if (userHasPermission(roles, canApproveEndClientAccessRequests)) {
    return colPipe(col, "id", "approveEndClientAccessRequestsPipe");
  } else if (entityIdsList.type === AssignedEntitiesListTypes.partial) {
    return batchColPipe(
      col,
      "entityId",
      "in",
      entityIdsList.entityIds,
      "id",
      "partialApproveEndClientAccessRequestsPipe"
    );
  } else {
    return DataPipe.withInitialData(null, []);
  }
};

const createProfilesPipe = (
  requests: WithId<EndClientAccessRequest, "id">[],
  tenant: string
): DataPipe<UserProfile[]> =>
  getProfilesByUidPipe(
    getProfileCol()
      .where("tenant", "==", tenant)
      .where("disabled", "==", false),
    requests.map((request) => request.requesterUid),
    "approveEndClientAccessRequestsProfilePipe"
  );

const createEndClientAccessRequestsPipe = (
  tenant: string,
  roles: RolesMap,
  entityIdsList: AssignedEntitiesList
): DataPipe<FullUserInfo[]> =>
  createBasicEndClientAccessRequestsPipe(
    roles,
    tenant,
    [
      EndClientAccessRequestStatus.pending,
      EndClientAccessRequestStatus.ignored,
    ],
    entityIdsList
  ).pipeToPipe((requests) =>
    createProfilesPipe(requests, tenant).pipe((profiles) =>
      leftJoin(
        requests,
        profiles,
        "requesterUid",
        "uid",
        (accessRequest, profile): FullUserInfo => ({
          ...notNull(profile),
          accessRequest,
          roles: [Roles.endClientUser],
          id: accessRequest.id,
        })
      )
    )
  );

const filterResults = (
  pipe: DataPipe<FullUserInfo[]>,
  filters: UsersListPipeFilters
) =>
  pipe.pipe((users) =>
    users.filter(
      ({ accessRequest }) =>
        accessRequest &&
        ((accessRequest.status === EndClientAccessRequestStatus.pending &&
          filters?.showPendingEndClientAccessRequests) ||
          (accessRequest.status === EndClientAccessRequestStatus.ignored &&
            filters?.showIgnoredEndClientAccessRequests))
    )
  );

export const createApproveEndClientAccessRequestsPipe = ({
  tenant,
  roles,
  filters,
  entityIdsList,
}: UsersListDataPipeArgs): DataPipe<FullUserInfo[]> => {
  if (!filters?.showPendingEndClientAccessRequests || !entityIdsList) {
    return DataPipe.withInitialData(null, []);
  }
  return filterResults(
    createEndClientAccessRequestsPipe(tenant, roles, entityIdsList),
    filters
  );
};
