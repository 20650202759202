/* DON'T EDIT THIS FILE: edit original and run build again */ import { B2cOptions } from "../../../framework/tenant-handler-type/b2c-options.ts";

class MatchfinB2cOptions implements B2cOptions {
  allowPersonalAccountsAsEndClients(): boolean {
    return true;
  }

  endClientHasWhatsappButton(): boolean {
    return true;
  }

  endClientHasRiskPreview(): boolean {
    return true;
  }

  endClientContactAdvisor(): boolean {
    return false;
  }

  getAdvisorPhoneNumberForB2CCampaigns(): string | null {
    return "5491134550101";
  }

  endClientNavbarColor(): string | null {
    return null;
  }

  endClientLandingBackgroundPath(): string | null {
    return null;
  }
}

export const matchfinB2cOptions = new MatchfinB2cOptions();
