/* DON'T EDIT THIS FILE: edit original and run build again */ import { doesUserCompanyHaveTenantLimits } from "../../credit/user-company-limit/data.ts";
import { UserProfile } from "../../framework/auth-profile/schema.ts";
import { canAccessCounterpartiesPage } from "../../framework/counterparties-permissions/permissions.ts";
import { createCounterpartiesWithLimitsPipe } from "../../framework/counterparties/create-user-companies-pipe.ts";
import { DataPipe } from "../../framework/data-pipe/data-pipe.ts";
import { RolesMap } from "../../framework/permission/schema.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { SidebarNotification } from "../../framework/sidebar-type/sidebar-types.ts";

export const getCounterpartiesHasNotificationPipe = ({
  roles,
  tenant,
  profile,
}: {
  roles: RolesMap;
  tenant: string;
  profile: UserProfile;
}): DataPipe<SidebarNotification> => {
  if (userHasPermission(roles, canAccessCounterpartiesPage)) {
    return createCounterpartiesWithLimitsPipe(tenant, profile, {}).pipe(
      (userCompanies) => {
        return {
          unread:
            userCompanies &&
            userCompanies.some(
              (userCompany) =>
                (doesUserCompanyHaveTenantLimits(userCompany) &&
                  !userCompany.limits) ||
                userCompany.limits?.isNew
            )
              ? 1
              : 0,
          badge: "Nuevas contrapartes",
        };
      }
    );
  } else {
    return DataPipe.withInitialData(null, { unread: 0, badge: null });
  }
};
