/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canViewQuantAnalysisDetail,
  canViewQuantAnalysisResult,
  canWriteQuantAnalysisAmount,
} from "./permissions.ts";

const quantAnalysisPermissions: PermissionCollection = {
  namespace: "quantAnalysis",
  permissions: [
    {
      name: canViewQuantAnalysisResult,
      description: "Puede ver al resultado del análisis Quant",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.portfolioManager,
      ],
    },
    {
      name: canViewQuantAnalysisDetail,
      description: "Puede ver el detalle del análisis Quant",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.portfolioManager,
      ],
    },
    {
      name: canWriteQuantAnalysisAmount,
      description: "Puede modificar/establecer el resultado del análisis Quant",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
      ],
    },
  ],
};

export default quantAnalysisPermissions;
