/* DON'T EDIT THIS FILE: edit original and run build again */ import { canViewRiskMatrix } from "../../credit/risk-matrix-permissions/permissions.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { SidebarMenuItem } from "../../framework/sidebar-type/sidebar-types.ts";
import { MatrixIcon } from "../../framework/theme-icons/icon.ts";

export const riskMatrixTabId = "riskMatrix";

const riskMatrixMenuItem: SidebarMenuItem = {
  id: riskMatrixTabId,
  getPath: () => "/matrices-de-riesgo",
  label: "Matriz de riesgo",
  condition: ({ tenantHandler, roles }) =>
    tenantHandler.supportsOnboardings() &&
    tenantHandler.supportsRiskMatrix() &&
    userHasPermission(roles, canViewRiskMatrix),
  order: 5,
  hasPriority: true,
  icon: (className) => <MatrixIcon className={className} />,
};

export default riskMatrixMenuItem;
