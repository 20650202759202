/* DON'T EDIT THIS FILE: edit original and run build again */ import { useLiveImmediateInput } from "../../../credit/live-form/ui/live-immediate-input.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../../credit/live-form/ui/types.ts";
import { AttachmentRef } from "../../../framework/attachment-type/attachment-ref-type.ts";
import { FileInput } from "../../../framework/attachment-ui/attachment.tsx";
import { SingleFileItemWidget } from "../../../framework/attachment-ui/single-file-item-widget.tsx";
import { now } from "../../../framework/now/now.ts";
import { FileLiveField } from "./live-field.ts";
import { fileLiveFieldType } from "./type.ts";

const Input: LiveFieldInput = (fieldInfo) => {
  const { liveCell } = fieldInfo;
  const liveField = liveCell.getLiveField() as FileLiveField;
  const liveData = liveCell.getLiveData();
  const uid = liveData.specificFolder?.ownerUid;
  if (!uid) {
    throw new Error("missingUidForFileInput");
  }
  const saveUploadTime = liveField.getSaveUploadTime();
  const liveInmediateInput = useLiveImmediateInput(fieldInfo);
  return (
    <FileInput
      {...liveInmediateInput}
      setValue={(value: AttachmentRef | null) => {
        liveInmediateInput.setValue(
          saveUploadTime && value != null
            ? { ...value, creationTime: now() }
            : value
        );
      }}
      uid={uid}
      uploadLabel={liveField.getUploadLabel()}
      canDelete={liveField.canDelete()}
      downloadLabel={"Ver documento"}
      showPreview={true}
    />
  );
};

const Viewer: LiveFieldViewer = ({ liveCell }) => {
  const value = liveCell.getValue();
  if (value) {
    return <SingleFileItemWidget attachment={value as AttachmentRef} />;
  } else {
    return null;
  }
};

export const fileLiveFieldRenderers: LiveFieldRenderer = {
  type: fileLiveFieldType,
  Input,
  Viewer,
};
