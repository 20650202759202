/* DON'T EDIT THIS FILE: edit original and run build again */ import { DocumentHeaderParams } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseSgrOnboardingOptions } from "../../../framework/tenant-handlers/base-sgr-onboarding-options.ts";
import {
  AFIP,
  EMAIL,
} from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";

class AvaluarOnboardingOptions extends BaseSgrOnboardingOptions {
  hasSignatura(): boolean {
    return true;
  }

  hasRevenueVolume(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  hasSwornStatement(): boolean {
    return true;
  }

  async getDocumentHeader(): Promise<ComponentType<DocumentHeaderParams>> {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }

  useSnailMailViaEmail(): boolean {
    return false;
  }

  getSnailMailSignatureAddress(): string {
    return "Olga Cossettini 831 P2 - Avaluar SGR - CABA";
  }

  getEmailAfterLabelText(): string | null {
    return null;
  }

  getOpenAccountAgreementLabel(): string {
    return 'Identificación del "Socio participe/tercero MIPyME"';
  }
}

export const avaluarOnboardingOptions = new AvaluarOnboardingOptions();
