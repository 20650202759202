/* DON'T EDIT THIS FILE: edit original and run build again */ import { getEntityId } from "../../credit/entity/get-entity-id.ts";
import { argentinaCountryCode } from "../../framework/country/country-codes.ts";
import {
  commonInternalRoles,
  endClientRoles,
  fciSpecificInternalRoles,
  financerRoles,
  matchfinSpecificInternalRoles,
} from "../../framework/internal-roles/internal-roles.ts";
import { getEmailWithName } from "../../framework/mail/get-email-with-name.ts";
import { Roles, RolesMap } from "../../framework/permission/schema.ts";
import { B2cOptions } from "../../framework/tenant-handler-type/b2c-options.ts";
import { LineOptions } from "../../framework/tenant-handler-type/line-options.ts";
import { OnboardingOptions } from "../../framework/tenant-handler-type/onboarding-options.ts";
import { TenantHandler } from "../../framework/tenant-handler-type/tenant-handler.ts";
import { matchfinTenant } from "../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";
import { GenericIconProps } from "../../framework/theme-icons/generic-icon.tsx";
import { ColorScheme } from "../../framework/theme/color-scheme-type.ts";
import { investorUserCompanyTypeId } from "../../framework/user-company-type-const/user-company-type-const.ts";
import { ComponentType, SVGProps } from "react";
import { BaseLineOptions } from "./base-line-options.ts";

const defaultLineOptions = new BaseLineOptions();

export abstract class BaseTenantHandler implements TenantHandler {
  private entityId: string | null;

  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>> | null> {
    return null;
  }

  async getSidebarPageSmallLogo(): Promise<ComponentType<GenericIconProps>> {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  hasOpinionReportSection(): boolean {
    return true;
  }

  abstract getLabel(): string;

  abstract getCode(): string;

  abstract getCuit(): string | null;

  abstract getUserCompanyType(): string | null;

  abstract getPathLabel(): string | null;

  abstract getFullLabel(): string | null;

  getComplianceEmailAddress(): string | null {
    return null;
  }

  canSignUp(): boolean {
    return (
      this.supportsOnboardings() ||
      this.doEndClientUsersHaveAccessToTheirEndClientLines()
    );
  }

  getLineOptions(): BaseLineOptions | null {
    return defaultLineOptions;
  }

  supportsOnboardings(): boolean {
    return this.getOnboardingOptions() !== null;
  }

  getNotificationsFromWithName() {
    return getEmailWithName(this.getNotificationsFrom(), this.getFullLabel());
  }

  abstract getNotificationsFrom(): string;

  getOnboardingOptions(): OnboardingOptions | null {
    return null;
  }

  getOnboardingOptionsOrFail() {
    const options = this.getOnboardingOptions();
    if (!options) {
      throw new Error("noOnboardingOptionsForTenant: " + this.getCode());
    }
    return options;
  }

  getLineOptionsOrFail(): LineOptions {
    const options = this.getLineOptions();
    if (!options) {
      throw new Error("noLineOptionsForTenant: " + this.getCode());
    }
    return options;
  }

  canCreateInviteLinks(): boolean {
    return true;
  }

  getEntityId() {
    if (!this.entityId) {
      this.entityId = getEntityId({
        cuit: this.getCuit() ?? undefined,
        country: this.getTenantCompanyCountry(),
        foreignIdNumber: this.getTenantCompanyForeignIdNumber() ?? undefined,
      });
    }
    return this.entityId;
  }

  publishesInMarketplace(): boolean {
    return false;
  }

  supportsFinancerUsers(): boolean {
    return false;
  }

  getTenantCompanyCountry(): string {
    return argentinaCountryCode;
  }

  getTenantCompanyForeignIdNumber(): string | null {
    return null;
  }

  supportsDomesticBankAccounts(): boolean {
    return true;
  }

  supportsBcraBankAccounts(): boolean {
    return true;
  }

  doEndClientUsersHaveAccessToTheirEndClientLines(): boolean {
    return false;
  }

  doProducersSignUpViaMatchfinRealm(): boolean {
    return false;
  }

  hasGrantedLineSection(): boolean {
    return false;
  }

  hasB2cFeature(): boolean {
    return false;
  }

  getMavAgentId(): number | null {
    return null;
  }

  getOfficeBranches(): { id: string; name: string }[] | null {
    return null;
  }

  usesDataLoadedStatus(): boolean {
    return false;
  }

  hasSemaphoreSettings(): boolean {
    return true;
  }

  hasSgrsReportsSection(): boolean {
    return this.getCode() === matchfinTenant;
  }

  getTraderEmailAddress(): string | null {
    return null;
  }

  getBackOfficeEmailAddress(): string | null {
    return null;
  }

  getApprovedOrdersEmailAddress(): string | null {
    return null;
  }

  getRejectedOrdersEmailAddress(): string | null {
    return null;
  }

  supportsOrderRouting(): boolean {
    return false;
  }

  supportsRiskMatrix(): boolean {
    return true;
  }

  getColors(): ColorScheme {
    return {};
  }

  getInternalRoles = (roles: RolesMap): Roles[] => {
    const out: Roles[] = [...commonInternalRoles];
    if (this.getCode() === matchfinTenant && roles[Roles.admin]) {
      out.push(...matchfinSpecificInternalRoles);
    }
    if (this.getUserCompanyType() === investorUserCompanyTypeId) {
      out.push(...fciSpecificInternalRoles);
    }
    return out;
  };

  isRoleInternal = (role: Roles, userRoles: RolesMap): boolean =>
    this.getInternalRoles(userRoles).includes(role);

  getRolesAssignableByAdmin = (assignerRoles: RolesMap): Roles[] => {
    const out: Roles[] = [];
    if (assignerRoles[Roles.admin] || assignerRoles[Roles.tenantAdmin]) {
      const filteredCommonInternalRoles = commonInternalRoles.filter(
        (role) => role !== Roles.producer
      );
      out.push(...filteredCommonInternalRoles);
      if (this.getUserCompanyType() === investorUserCompanyTypeId) {
        out.push(...fciSpecificInternalRoles);
      }
      if (this.getCode() === matchfinTenant && assignerRoles[Roles.admin]) {
        out.push(
          ...matchfinSpecificInternalRoles,
          ...financerRoles,
          ...endClientRoles
        );
      }
    }
    return out;
  };

  b2cOptions(): B2cOptions | null {
    return null;
  }

  getB2cOptionOrFail(): B2cOptions {
    const options = this.b2cOptions();
    if (!options) {
      throw new Error("noLineOptionsForTenant: " + this.getCode());
    }
    return options;
  }
}
