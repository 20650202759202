/* DON'T EDIT THIS FILE: edit original and run build again */ import { ListRow } from "../../../credit/list-section/list-row.tsx";
import { labelLiveFieldType } from "../../../credit/live-form-core-types/label/type.ts";
import { LiveFieldset } from "../../../credit/live-form/fieldset.ts";
import { LiveRecord } from "../../../credit/live-form/live-record.ts";
import { LiveData } from "../../../credit/live-form/types.ts";
import { RawLiveFormViewer } from "../../../credit/live-form/ui/raw-live-form-viewer.tsx";
import { LiveFieldGetLiveFormInputFactory } from "../../../credit/live-form/ui/types.ts";
import { Jsonable } from "../../../framework/core/jsonable.ts";
import { Div } from "../../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { useState } from "react";
import { Button } from "react-bootstrap";

type ManualSortingDirection = -1 | 1;

type RowProps = {
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  number: number | undefined;
  fieldset: LiveFieldset;
  liveData: LiveData;
  onEditClick: (() => void) | undefined;
  collapsed: boolean;
  Widgets: Record<string, any>;
  collapsedRowPreviewLabel: string;
  showErrors: boolean;
  moveItem:
    | ((
        item: { orderId: number },
        direction: ManualSortingDirection
      ) => Promise<void>)
    | null;
};

type ExpandedRowProps = RowProps & {
  renderTitle: (liveData: LiveData) => React.ReactNode;
};

const isOrderItem = (item: Jsonable): item is { orderId: number } => {
  return !!item && typeof item === "object" && "orderId" in item;
};

const ManualSortingButtons = ({
  liveData,
  moveItem,
}: {
  liveData: LiveData;
  moveItem: (item: { orderId: number }, direction: 1 | -1) => Promise<void>;
}) => {
  const directions: ManualSortingDirection[] = [-1, 1];
  return (
    <Div className="d-flex flex-row flex-gap-3">
      {directions.map((direction: ManualSortingDirection) => (
        <Button
          key={direction}
          className="border-0 text-gray40 p-0"
          onClick={async () => {
            const item = liveData.recordValue;
            if (!isOrderItem(item)) {
              throw new Error("itemIsNotManuallySortable");
            }
            await moveItem(item, direction);
          }}
        >
          {direction === 1 ? "▼" : "▲"}
        </Button>
      ))}
    </Div>
  );
};

export const ExpandedListingRow = ({
  getLiveFormInputFactory,
  number,
  fieldset,
  liveData,
  onEditClick,
  renderTitle,
  Widgets,
  showErrors,
  moveItem,
}: ExpandedRowProps) => {
  const liveRecord = new LiveRecord(fieldset, liveData);
  const error = showErrors ? liveRecord.getError() : null;
  const filteredLiveRecord = new LiveRecord(
    fieldset.filter((liveField) => {
      const hideOnRow = liveField.hideOnRow();
      return (
        !hideOnRow &&
        (liveField.getType() === labelLiveFieldType ||
          !liveField.isEmpty(liveData))
      );
    }),
    liveData
  );
  return (
    <>
      <ListRow
        number={number}
        incomplete={!!error}
        onEditClick={onEditClick}
        items={[
          <Div key="liveFormViewer" className="flex-grow-1 d-flex">
            {renderTitle(liveData)}
          </Div>,
          ...(moveItem
            ? [
                <ManualSortingButtons
                  key="sortingButtons"
                  liveData={liveData}
                  moveItem={moveItem}
                />,
              ]
            : []),
        ]}
      />
      <Div key="liveFormViewer" className="flex-grow-1 ms-4">
        <RawLiveFormViewer
          getLiveFormInputFactory={getLiveFormInputFactory}
          liveRecord={filteredLiveRecord}
          Widgets={Widgets}
        />
        {error ? <Div className="text-warning">{error}</Div> : null}
      </Div>
    </>
  );
};

export const CompactListingRow = ({
  getLiveFormInputFactory,
  number,
  fieldset,
  liveData,
  onEditClick,
  collapsed: initialCollapsed,
  Widgets,
  collapsedRowPreviewLabel,
  showErrors,
  moveItem,
}: RowProps) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const liveRecord = new LiveRecord(fieldset, liveData);
  const error = showErrors ? liveRecord.getError() : null;
  return (
    <ListRow
      number={number}
      incomplete={!!error}
      onEditClick={collapsed ? undefined : onEditClick}
      items={[
        collapsed ? (
          <Div
            key="collapser"
            className="flex-grow-1 cursor-pointer ms-2"
            onClick={() => setCollapsed(false)}
          >
            {collapsedRowPreviewLabel}
          </Div>
        ) : (
          <Div key="liveFormViewer" className="flex-grow-1 p-3">
            <RawLiveFormViewer
              getLiveFormInputFactory={getLiveFormInputFactory}
              liveRecord={liveRecord}
              showLabels={false}
              Widgets={Widgets}
            />
            {error ? <Div className="text-warning">{error}</Div> : null}
            {initialCollapsed ? (
              <Button
                onClick={() => setCollapsed(true)}
                variant="blue50 rounded-2"
                size="sm"
              >
                Contraer
              </Button>
            ) : null}
          </Div>
        ),
        ...(moveItem
          ? [
              <ManualSortingButtons
                key="sortingButtons"
                liveData={liveData}
                moveItem={moveItem}
              />,
            ]
          : []),
      ]}
    />
  );
};
