/* DON'T EDIT THIS FILE: edit original and run build again */ import { B2cOptions } from "../../../framework/tenant-handler-type/b2c-options.ts";
import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { avaluarB2cOptions } from "./avaluar-b2c-options.ts";
import { avaluarOnboardingOptions } from "./avaluar-onboarding-options.ts";
import { avaluarTenant } from "./code.ts";
import { avaluarFullLabel } from "./full-label.ts";
import { avaluarLabel } from "./label.ts";

class AvaluarTenantHandler extends SgrTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_avaluar.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return avaluarOnboardingOptions;
  }

  getCode(): string {
    return avaluarTenant;
  }

  getPathLabel(): string | null {
    return "avaluar";
  }

  getLabel(): string {
    return avaluarLabel;
  }

  getFullLabel(): string {
    return avaluarFullLabel;
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  getComplianceEmailAddress(): string {
    return "compliance@avaluar.com.ar";
  }

  getNotificationsFrom(): string {
    return "no-responder@avaluar.com.ar";
  }

  getCuit(): string | null {
    return "30-69728110-6";
  }

  hasGrantedLineSection(): boolean {
    return true;
  }

  hasB2cFeature(): boolean {
    return true;
  }

  doEndClientUsersHaveAccessToTheirEndClientLines(): boolean {
    return true;
  }

  b2cOptions(): B2cOptions | null {
    return avaluarB2cOptions;
  }

  getColors(): ColorScheme {
    return {
      primary: "#F46E67",
      secondary: "#495867",
      tertiary: "#F4F6F4",
    };
  }

  hasLufe(): boolean {
    return true;
  }
}

const avaluarTenantHandler = new AvaluarTenantHandler();
export default avaluarTenantHandler;
