/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessB2cControlPanel,
  canAccessControlPanel,
  canAccessMatchfinControlPanel,
} from "./permissions.ts";

const controlPanelPermissions: PermissionCollection = {
  namespace: "controlPanel",
  permissions: [
    {
      name: canAccessControlPanel,
      description: "Puede acceder al panel de control",
      roles: [
        Roles.endClientUser,
        Roles.matchfinEndClientUser,
        Roles.b2cEndclientUser,
      ],
    },
    {
      name: canAccessMatchfinControlPanel,
      description: "Puede acceder al panel de control de MatchFin",
      roles: [Roles.matchfinEndClientUser],
    },
    {
      name: canAccessB2cControlPanel,
      description: "Puede acceder al panel de control de portales B2C",
      roles: [Roles.b2cEndclientUser],
    },
  ],
};

export default controlPanelPermissions;
