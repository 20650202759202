/* DON'T EDIT THIS FILE: edit original and run build again */ import { canAccessControlPanel } from "../../credit/control-panel-permissions/permissions.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { SidebarMenuItem } from "../../framework/sidebar-type/sidebar-types.ts";
import { HomeIcon } from "../../framework/theme-icons/icon.ts";

export const controlPanelTabId = "controlPanel";

const controlPanelMenuItem: SidebarMenuItem = {
  id: controlPanelTabId,
  getPath: () => "/panel-de-control",
  label: "Panel de control",
  condition: ({ roles }) => userHasPermission(roles, canAccessControlPanel),
  order: 8,
  hasPriority: true,
  icon: (className) => <HomeIcon className={className} />,
};

export default controlPanelMenuItem;
