/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessAnyConversationTopic,
  canAccessChat,
  canAccessConversationsWithLineSharingTopic,
  canAccessConversationsWithLineSharingTopicAsRecipientCounterparty,
  canAccessGroupConversations,
} from "./permissions.ts";

const chatPermissions: PermissionCollection = {
  namespace: "chat",
  permissions: [
    {
      name: canAccessChat,
      description: "Puede acceder a la sección chat",
      roles: [
        Roles.matchfinEndClientUser,
        Roles.admin,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.sales,
        Roles.producer,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.backOffice,
        Roles.trader,
        Roles.salesManager,
        Roles.headOfProducers,
        Roles.portfolioManager,
        Roles.complianceOfficer,
        Roles.b2cEndclientUser,
        Roles.tenantAdmin,
      ],
    },
    {
      name: canAccessGroupConversations,
      description: "Puede acceder a conversaciones con grupos",
      roles: [
        Roles.admin,
        Roles.backOffice,
        Roles.trader,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.salesManager,
        Roles.portfolioManager,
        Roles.analyst,
        Roles.tenantAdmin,
      ],
    },
    {
      name: canAccessAnyConversationTopic,
      description:
        "Puede acceder a conversaciones de cualquier topico: ordenes, carpetas",
      roles: [Roles.admin, Roles.tenantAdmin],
    },
    {
      name: canAccessConversationsWithLineSharingTopic,
      description: "Puede acceder a conversaciones con tópico carpetas",
      roles: [
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.backOffice,
        Roles.trader,
        Roles.salesManager,
        Roles.portfolioManager,
      ],
    },
    {
      name: canAccessConversationsWithLineSharingTopicAsRecipientCounterparty,
      description:
        "Puede acceder a conversaciones con tópico carpetas como contraparte receptora",
      roles: [Roles.analyst],
    },
  ],
};

export default chatPermissions;
