/* DON'T EDIT THIS FILE: edit original and run build again */ import { Spinner } from "react-bootstrap";

export const Loading = () => (
  <div className="d-flex justify-content-center">
    <Spinner
      style={{ width: "3rem", height: "3rem" }}
      className="m-4"
      animation="border"
      variant="gray40"
      children=""
    />
  </div>
);

export const SmallLoading = () => (
  <Spinner animation="border" variant="gray40" size="sm" children="" />
);

export const BigLoading = ({ color }: { color: string | undefined }) => (
  <Spinner
    animation="border"
    className="m-6"
    style={{
      width: "5rem",
      height: "5rem",
      color: color,
    }}
  />
);
