/* DON'T EDIT THIS FILE: edit original and run build again */ export const saCompanyType = "SA";
export const srlCompanyType = "SRL";
export const sasCompanyType = "SAS";
export const shCompanyType = "SH";
export const sauCompanyType = "SAU";
export const scaCompanyType = "SCA";
export const cooperativeCompanyType = "COOPERATIVA";
export const transitoryUnionType = "UNION_TRANSITORIA";
export const trustType = "FIDEICOMISO";
export const financialTrustType = "FIDEICOMISO FINANCIERO";
export const mutualGuaranteeType = "SGR";
export const associationType = "ASOCIACION";
export const publicAgencyType = "ORGANISMO_PUBLICO";
export const capISeccionIvType = "CAP_I_SECCION_IV";
export const beingFormedCompanyType = "EN_FORMACION";
// "cajas" or "colegios"
export const publicNotStateLevelCompanyType = "PUBLICA_NO_ESTATAL";
export const collaborationGroupType = "AGRUPACION_COLABORACION";
export const majorityStateParticipationType = "CON_PART_ESTATAL_MAYORITARIA";
export const condominiumType = "CONDOMINIO";
export const ownersConsortiumType = "CONSORCIO_DE_PROPIETARIOS";
export const cooperationConsortiumType = "CONSORCIO_DE_COOPERACION";
export const cooperativeType = "COOPERADORA";
export const stateCompanyType = "EMPRESA_DEL_ESTADO";
export const publicTrustType = "FIDEICOMISO_PUBLICO";
export const foundationType = "FUNDACION";
export const religiousInstituteType = "INSTITUTO_VIDA_CONSAGRADA";
export const mutualType = "MUTUAL";
export const internationalPublicAgencyType = "ORGANISMO_PUBLICO_INTERNACIONAL";
export const otherCivilEntitiesType = "OTRAS_ENTIDADES_CIVILES";
export const otherCompaniesType = "OTRAS_SOCIEDADES";
export const simpleAssociationType = "SIMPLE_ASOCIACION";
export const collectiveCompanyType = "SOCIEDAD_COLECTIVA";
export const foreignCompanyType = "SOCIEDAD_EXTRANJERA";
export const foreignBranchType = "SUCURSAL_EMPRESA_EXTRANJERA";

export const allKnownCompanyTypes = [
  saCompanyType,
  srlCompanyType,
  sasCompanyType,
  shCompanyType,
  sauCompanyType,
  scaCompanyType,
  cooperativeCompanyType,
  transitoryUnionType,
  trustType,
  financialTrustType,
  mutualGuaranteeType,
  associationType,
  publicAgencyType,
  capISeccionIvType,
  beingFormedCompanyType,
  publicNotStateLevelCompanyType,
  collaborationGroupType,
  majorityStateParticipationType,
  condominiumType,
  ownersConsortiumType,
  cooperationConsortiumType,
  cooperativeType,
  stateCompanyType,
  publicTrustType,
  foundationType,
  religiousInstituteType,
  mutualType,
  internationalPublicAgencyType,
  otherCivilEntitiesType,
  otherCompaniesType,
  simpleAssociationType,
  collectiveCompanyType,
  foreignCompanyType,
  foreignBranchType,
];

export const companyTypeLabels = {
  [saCompanyType]: "SA",
  [srlCompanyType]: "SRL",
  [sasCompanyType]: "SAS",
  [shCompanyType]: "Sociedad de hecho",
  [sauCompanyType]: "SAU",
  [scaCompanyType]: "SCA",
  [cooperativeCompanyType]: "Cooperativa",
  [transitoryUnionType]: "Unión transitoria",
  [trustType]: "Fideicomiso",
  [financialTrustType]: "Fideicomiso Financiero",
  [mutualGuaranteeType]: "SGR",
  [associationType]: "Asociación",
  [publicAgencyType]: "Organismo público",
  [capISeccionIvType]: "Cap. I sección IV",
  [beingFormedCompanyType]: "En formación",
  [publicNotStateLevelCompanyType]: "Pública no estatal",
  [mutualType]: "Mutual",
  [collaborationGroupType]: "Agrupación de colaboración empresaria",
  [majorityStateParticipationType]: "Con Participación Estatal Mayoritaria",
  [condominiumType]: "Condominio",
  [ownersConsortiumType]: "Consorcio de propietarios",
  [cooperationConsortiumType]: "Consorcio de cooperación",
  [cooperativeType]: "Cooperadora",
  [stateCompanyType]: "Empresa del estado",
  [publicTrustType]: "Fideicomiso público",
  [foundationType]: "Fundación",
  [religiousInstituteType]: "Instituto de vida consagrada",
  [internationalPublicAgencyType]: "Organismo público internacional",
  [otherCivilEntitiesType]: "Otras entidades civiles",
  [otherCompaniesType]: "Otras sociedades",
  [simpleAssociationType]: "Simple asociación",
  [collectiveCompanyType]: "Sociedad colectiva",
  [foreignCompanyType]: "Sociedad extranjera",
  [foreignBranchType]: "Sucursal empresa extranjera",
};
