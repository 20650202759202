/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessUsers,
  canAddEndClient,
  canCreateInviteLinks,
  canCreateProducerInviteLinks,
  canDisableOrEnableOtherUsers,
  canEditOtherUsersProfile,
  canEditOtherUsersRoles,
  canEditProfile,
  canHaveSourceCode,
  canListAllUsers,
  canListAssignedEndClientProfiles,
  canListDisabledUsers,
  canListExternalTenantProfiles,
  canListInternalTenantProfiles,
  canListProducerAssignedEndClientProfiles,
  canListProducerTenantProfiles,
  canListTenantProfiles,
  canListUserCompanies,
  canMigrateFinancerUsers,
  canSeeOtherUsersRoles,
  canViewAdminsIdentity,
  canViewMatchfinAnalystsIdentity,
  canViewTenantProfiles,
} from "./permissions.ts";

const usersPermissions: PermissionCollection = {
  namespace: "users",
  permissions: [
    {
      name: canAccessUsers,
      description: "Puede acceder a la seccion usuarios",
      roles: [
        Roles.admin,
        Roles.tenantAdmin,
        Roles.sales,
        Roles.producer,
        Roles.backOffice,
        Roles.trader,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.complianceOfficer,
      ],
    },
    {
      name: canSeeOtherUsersRoles,
      description: "Puede ver los roles de otros usuarios",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.backOffice,
        Roles.trader,
        Roles.matchfinSales,
        Roles.portfolioManager,
        Roles.complianceOfficer,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
    {
      name: canCreateInviteLinks,
      description: "Puede crear invitaciones para nuevos usuarios",
      roles: [Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canCreateProducerInviteLinks,
      description:
        "Puede crear invitaciones para nuevos usuarios con rol productor",
      roles: [Roles.headOfProducers],
    },
    {
      name: canEditProfile,
      description: "Puede editar los datos del perfil de su propio usuario",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.investor,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.backOffice,
        Roles.trader,
        Roles.pendingApproval,
        Roles.tenantRealmPendingApproval,
        Roles.endClientUser,
        Roles.matchfinEndClientUser,
        Roles.b2cEndclientUser,
        Roles.portfolioManager,
        Roles.complianceOfficer,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
    {
      name: canAddEndClient,
      description: "Puede agregar un nuevo cliente final",
      roles: [Roles.endClientUser],
    },
    {
      name: canListAllUsers,
      description: "Puede listar todos los usuarios",
      roles: [Roles.admin, Roles.matchfinSales],
    },
    {
      name: canListDisabledUsers,
      description: "Puede listar usuarios desabilitados",
      roles: [Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canListInternalTenantProfiles,
      description: "Puede listar usuarios internos al tenant",
      roles: [
        Roles.tenantAdmin,
        Roles.admin,
        Roles.backOffice,
        Roles.trader,
        Roles.sales,
        Roles.salesManager,
      ],
    },
    {
      name: canListExternalTenantProfiles,
      description: "Puede listar usuarios externos al tenant",
      roles: [
        Roles.tenantAdmin,
        Roles.admin,
        Roles.backOffice,
        Roles.trader,
        Roles.salesManager,
        Roles.complianceOfficer,
      ],
    },
    {
      name: canListProducerTenantProfiles,
      description: "Puede listar usuarios productores",
      roles: [Roles.headOfProducers],
    },
    {
      name: canListTenantProfiles,
      description: "Puede listar todos los perfiles del tenant",
      roles: [
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.tenantAdmin,
        Roles.admin,
        Roles.backOffice,
        Roles.trader,
        Roles.headOfProducers,
        Roles.sales,
        Roles.salesManager,
        Roles.complianceOfficer,
      ],
    },
    {
      name: canViewTenantProfiles,
      description: "Puede ver los perfiles del tenant",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
        Roles.complianceOfficer,
      ],
    },
    {
      name: canListProducerAssignedEndClientProfiles,
      description: "Puede listar los clientes asignados a un productor",
      roles: [Roles.headOfProducers],
    },
    {
      name: canListAssignedEndClientProfiles,
      description: "Puede listar los usuarios EndClients asignados",
      roles: [
        Roles.producer,
        Roles.sales,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
    {
      name: canDisableOrEnableOtherUsers,
      description: "Puede deshabilitar o habilitar otros usuarios",
      roles: [Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canEditOtherUsersProfile,
      description: "Puede modificar la información de otros usuario",
      roles: [Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canEditOtherUsersRoles,
      description: "Puede modificar los roles de otros usuarios",
      roles: [Roles.tenantAdmin, Roles.admin],
    },
    {
      name: canMigrateFinancerUsers,
      description:
        " Puede migrar usuarios con rol Financer de un tenant a otro, modificando su rol",
      roles: [Roles.admin],
    },

    {
      name: canListUserCompanies,
      description: "Puede listar las companias asociadas al usuario",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.endClientUser,
      ],
    },
    {
      name: canViewAdminsIdentity,
      description: "Puede ver la identidad de los administradores",
      roles: [Roles.admin],
    },
    {
      name: canViewMatchfinAnalystsIdentity,
      description: "Puede ver la identidad de los analistas de MatchFIn",
      roles: [Roles.admin, Roles.matchfinAnalyst],
    },
    {
      name: canHaveSourceCode,
      description: " ",
      roles: [
        Roles.sales,
        Roles.producer,
        Roles.headOfProducers,
        Roles.salesManager,
      ],
    },
  ],
};

export default usersPermissions;
