/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";
import { PermissionCollection } from "../../framework/permission/types.ts";
import {
  canAccessGrantedLines,
  canAccessLineInbox,
  canAccessReceivedLines,
  canAccessReceivedLinesInNonFciTenants,
} from "./permissions.ts";

const lineInboxPermissions: PermissionCollection = {
  namespace: "lineInbox",
  permissions: [
    {
      name: canAccessLineInbox,
      description:
        "Puede acceder a las carpetas compartidas, tanto enviadas como recibidas. Tambien puede ver los mensajes asociados a dicha carpeta compartida",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.matchfinEndClientUser,
        Roles.b2cEndclientUser,
        Roles.portfolioManager,
      ],
    },

    {
      name: canAccessGrantedLines,
      description: "Puede acceder a carpetas enviadas",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },

    {
      name: canAccessReceivedLinesInNonFciTenants,
      description:
        "Puede acceder a carpetas recibidas en tenant que no sean fondos comunes de inversion",
      roles: [
        Roles.sales,
        Roles.salesManager,
        Roles.producer,
        Roles.headOfProducers,
      ],
    },
    {
      name: canAccessReceivedLines,
      description: "Puede acceder a la sección de carpetas recibidas",
      roles: [
        Roles.investor,
        Roles.guestBank,
        Roles.guestSgr,
        Roles.analyst,
        Roles.matchfinAnalyst,
        Roles.admin,
        Roles.tenantAdmin,
        Roles.backOffice,
        Roles.trader,
        Roles.portfolioManager,
      ],
    },
  ],
};

export default lineInboxPermissions;
