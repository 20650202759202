/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentHeaderParams,
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import { ComponentImporter } from "../../../framework/theme/component-importer-type.ts";
import {
  emailChannelType,
  webHomeChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import { EMAIL } from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";
import onboardingTermsOfUseTitle from "./onboarding-terms-of-use-title.ts";

class MultiploBursatilOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  termsOfUseImporter: ComponentImporter<TermsOfUseParams> = () => {
    return import("./onboarding-terms-of-use.tsx");
  };

  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  hasSnailMail(): boolean {
    return false;
  }

  hasAune(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [emailChannelType, webHomeChannelType];
  }

  async getDocumentHeader(): Promise<ComponentType<DocumentHeaderParams>> {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }

  webHomeHeaderImporter: ComponentImporter<WebHomeHeaderParams> = async () => {
    return import("./web-home-header.tsx");
  };

  getAuneAccountIdCreationMethod(): "automatic" | "manual" | "both" {
    return "both";
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }

  hasFatca(): "simple" | "full" | "none" {
    return "full";
  }
}

export const multiploBursatilOnboardingOptions =
  new MultiploBursatilOnboardingOptions();
